import { Autocomplete } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import TextInput from "./TextInput";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";

const PartnerSelect = ({
  name,
  style,
  value,
  onChange,
  label,
  error,
  helperText,
}) => {
  const { data: partners, isLoading: isLoadingPartners } =
    useAgencyDropdownQuery({});

  return (
    <Autocomplete
      fullWidth
      style={style}
      id={name}
      options={!isLoadingPartners ? partners : []}
      getOptionLabel={(option) => option.text}
      value={
        !isLoadingPartners
          ? partners?.find((option) => option.value === value) || null
          : null
      }
      onChange={onChange}
      componentsProps={{
        popper: { style: { width: "fit-content" } },
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          name={name}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
PartnerSelect.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default PartnerSelect;
