import { Box, Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import AddAccommodationUnitModal from "components/Modals/AddAccommodationUnitModal/AddAccomodationUnitModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import modalConstants from "constants/modalConstants";
import {
  useAccommodationUnitDetailsQuery,
  useAccommodationUnitListQuery,
  useAddAccommodationUnitMutation,
  useDeleteAccommodationUnitMutation,
  useEditAccommodationUnitMutation,
} from "features/accommodationUnit/accommodationUnitApiSlice";
import { closeModal, openModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import useAccommodationUnitTable from "hooks/tables/useAccommodationUnitTable";
import accommodationUnitInitialValues from "initialValues/accommodationUnitInitialValues";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";

const AccommodationUnitPage = () => {
  const { t } = useTranslation();
  let params = useParams();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const confirmCloseModal = modals?.[modalConstants.CLOSE_CONFIRM_DIALOG];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({ name: "name", direction: "asc" });

  const { data: accommodationUnits, isLoading: isLoadingAccommodationUnits } =
    useAccommodationUnitListQuery({
      accommodationId: params?.id,
      page,
      rowsPerPage,
      sort,
    });

  const {
    data: singleAccommodationUnit,
    isLoading: isLoadingSingleAccommodationUnit,
  } = useAccommodationUnitDetailsQuery(
    { id: editModal?.data.id },
    { skip: !editModal?.open }
  );

  const [addAccommodationUnit, { isLoading: isLoadingAddAccommodationUnit }] =
    useAddAccommodationUnitMutation();

  const [editAccommodationUnit, { isLoading: isLoadingEditAccommodationUnit }] =
    useEditAccommodationUnitMutation();
  const [
    deleteAccommodationUnit,
    { isLoading: isLoadingDeleteAccommodationUnit },
  ] = useDeleteAccommodationUnitMutation();

  const { columns } = useAccommodationUnitTable({
    page,
    rowsPerPage,
  });

  const handleSubmit = (val) => {
    if (addModal?.open) {
      addAccommodationUnit({ ...val, accommodationId: Number(params.id) })
        .unwrap()
        .then(() => {
          if (!isLoadingAddAccommodationUnit) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodationUnit.addSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnit.addErrorMessage"))
        );
    }

    if (editModal?.open) {
      editAccommodationUnit({ ...val, accommodationId: Number(params.id) })
        .unwrap()
        .then(() => {
          if (!isLoadingEditAccommodationUnit) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodationUnit.editSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnit.updateErrorMessage"))
        );
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteAccommodationUnit({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteAccommodationUnit) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodationUnit.deleteSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnit.deleteErrorMessage"))
        );
    }
  };

  const handleCancelDelete = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
  };

  const transformUnitProperties = (unitProperties) => {
    return unitProperties?.map((property) => {
      if (typeof property === "object") {
        return property?.id;
      }
      return property;
    });
  };

  const detailAccommodationUnitValues = {
    ...singleAccommodationUnit,
    unitProperties: transformUnitProperties(
      singleAccommodationUnit?.unitProperties
    ),
  };

  const formik = useFormik({
    initialValues: editModal?.open
      ? isLoadingSingleAccommodationUnit
        ? accommodationUnitInitialValues
        : singleAccommodationUnit && detailAccommodationUnitValues
      : accommodationUnitInitialValues,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClosAddEdit = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (formik.dirty) {
        dispatch(openModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
      } else {
        if (addModal?.open) {
          dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
          formik.resetForm();
        } else {
          dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
          formik.resetForm();
        }
      }
    }
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      dispatch(openModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
    } else {
      if (addModal?.open) {
        dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
        formik.resetForm();
      } else {
        dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
        formik.resetForm();
      }
    }
  };

  const handleCancel = () => {
    dispatch(closeModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
  };

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  const handleCloseConfirmDialogAdd = () => {
    batch(() => {
      dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
      dispatch(closeModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
    });
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    batch(() => {
      dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
      dispatch(closeModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
    });
    formik.resetForm();
  };

  return (
    <PageContainer>
      <AddAccommodationUnitModal
        open={editModal?.open ? editModal?.open : addModal?.open}
        handleClose={handleClosAddEdit}
        handleCloseBtn={handleCloseBtn}
        formik={formik}
        title={
          editModal?.open
            ? t("accommodationUnit.editTitle", {name: accommodationUnits?.parentItemName})
            : t("accommodationUnit.addTitle", {name: accommodationUnits?.parentItemName})
        }
      />
      <ConfirmCloseDialog
        open={confirmCloseModal?.open}
        handleClose={
          editModal?.open ? handleCloseConfirmDialogEdit : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={t("accommodationUnit.confirmClose")}
      />
      <ConfirmModal
        open={deleteModal?.open}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("accommodationUnit.confirmDelete")}
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("accommodationUnit.pageTitle", {name: accommodationUnits?.parentItemName})}
      </Typography>
      <PageHeader>
        <Box />
        <Button variant="contained" onClick={handleOpenAdd}>
          {t("accommodationUnit.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={accommodationUnits?.data}
        isLoading={isLoadingAccommodationUnits}
        count={accommodationUnits?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("accommodationUnit.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default AccommodationUnitPage;
