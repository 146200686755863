import React from "react";
import PropTypes from "prop-types";
import { PageContainer } from "styles/SharedStyle.styled";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "components/Title/Title";
import InvoiceForm from "components/Forms/InvoiceForm/InvoiceForm";
import { useFormik } from "formik";
import invoiceInitialValues from "initialValues/invoiceInitialValues";
import invoiceValidation from "validations/invoiceValidation";
import proInvoiceValidation from "validations/proInvoiceValidation";

const AddEditInvoicePage = ({ isProInvoice }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  // based on isProInvoice and id create function that handle title
  const handleTitle = () => {
    if (isProInvoice) {
      return id ? t("proinvoice.editTitle") : t("proinvoice.addTitle");
    } else {
      return id ? t("invoice.editTitle") : t("invoice.addTitle");
    }
  };

  const handleSubmit = async (values) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: invoiceInitialValues,
    validationSchema: isProInvoice ? proInvoiceValidation : invoiceValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <PageContainer>
      <Title title={handleTitle()} />
      <InvoiceForm formik={formik} isProInvoice={isProInvoice} />
    </PageContainer>
  );
};
AddEditInvoicePage.propTypes = {
  isProInvoice: PropTypes.bool.isRequired,
};

export default AddEditInvoicePage;
