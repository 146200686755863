import { Box } from "@mui/material";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import {
  phoneNumberChars,
  preventNonNumericChars,
} from "util/helpers/preventNonNumericChars";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { usePartnerTypeDropdownQuery } from "features/partnerType/partnerTypeApiSlice";

const PartnerForm = ({ formik }) => {
  const { t } = useTranslation();
  const { data: partnerTypes } = usePartnerTypeDropdownQuery();
  const { data: countries } = useCountryDropdownQuery();
  const { data: locations } = useLocationDropdownQuery(
    { id: formik.values.countryId },
    { skip: !formik.values.countryId }
  );
  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("partners.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.partnerNamePlaceholder")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: "24px" }}
          />
          <SelectInput
            fullWidth
            name="partnerTypeId"
            label={t("field.partnerTypeIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.partnerTypeId}
            error={
              formik.touched.partnerTypeId &&
              Boolean(formik.errors.partnerTypeId)
            }
            helperText={
              formik.touched.partnerTypeId && formik.errors.partnerTypeId
            }
            style={{ marginRight: "24px" }}
            items={partnerTypes}
          />
          <TextInput
            fullWidth
            name="pib"
            label={t("field.pibPlaceholder")}
            value={formik.values.pib}
            onChange={formik.handleChange}
            error={formik.touched.pib && Boolean(formik.errors.pib)}
            helperText={formik.touched.pib && formik.errors.pib}
            onKeyDown={preventNonNumericChars}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 9);
            }}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="legalIdentificationNumber"
            label={t("field.legalIdentificationNumberPlaceholder")}
            value={formik.values.legalIdentificationNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.legalIdentificationNumber &&
              Boolean(formik.errors.legalIdentificationNumber)
            }
            helperText={
              formik.touched.legalIdentificationNumber &&
              formik.errors.legalIdentificationNumber
            }
            onKeyDown={preventNonNumericChars}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 8);
            }}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="email"
            label={t("field.emailPlaceholder")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberPlaceholder")}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
          />
        </RowContainer>
        <RowContainer>
          <CountrySelect
            fullWidth
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.countryId}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            style={{ marginRight: "24px" }}
            items={countries}
          />
          <LocationSelect
            fullWidth
            name="locationId"
            disabled={!formik.values.countryId}
            label={t("field.locationIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.locationId}
            error={
              formik.touched.locationId && Boolean(formik.errors.locationId)
            }
            helperText={formik.touched.locationId && formik.errors.locationId}
            items={locations}
            countryId={formik.values.countryId}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="address"
            label={t("field.addressPlaceholder")}
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </RowContainer>
      </FormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleConfirm={formik.handleSubmit}
        handleCancel={formik.resetForm}
      />
    </Box>
  );
};
PartnerForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
};
export default PartnerForm;
