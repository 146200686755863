import { Box } from "@mui/material";
import styled from "styled-components";
// Replace with styled.img when there is logo
export const MainContainer = styled(Box)`
 background-color: #FCFCFD;
 display: flex;
`;

export const ContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    transition: all 0.5s ease;
    background: linear-gradient(
     to bottom,
      #7EBBE3 0%,
      #8EC8E9 7px,
      #A1D7F1 35px,
      #C3F3FF 70px,
      #FFFFFF 135px,
      #FFFFFF 100%
);
`
