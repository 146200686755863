import { MenuItem, Pagination, Select, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  TableFooterContainer,
  TableFooterPagination,
  TableFooterRowsPerPage,
  TableFooterTotalCount,
} from "./CustomTableFooter.styled";

const CustomTableFooter = ({
  options,
  page,
  count,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  rowsName,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <TableFooterContainer>
      <TableFooterRowsPerPage>
        <Typography variant="body2">{`${
          options.textLabels.pagination.rowsPerPage
        } ${t("common.perPage")}`}</Typography>
        <Select
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={options.rowsPerPage}
          onChange={handleChange}
        >
          {options.rowsPerPageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </TableFooterRowsPerPage>
      <TableFooterTotalCount>
        <Typography variant="body2">{`${t("common.totalCount", {
          name: rowsName,
        })}: ${options.count ? options.count : 0}`}</Typography>
      </TableFooterTotalCount>
      <TableFooterPagination>
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(count / rowsPerPage)}
            page={page + 1}
            showFirstButton
            showLastButton
            onChange={(event, page) => {
              setPage(page - 1);
            }}
          />
        </Stack>
      </TableFooterPagination>
    </TableFooterContainer>
  );
};
CustomTableFooter.propTypes = {
  options: PropTypes.shape({
    count: PropTypes.number.isRequired,
    textLabels: PropTypes.shape({
      pagination: PropTypes.shape({
        rowsPerPage: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  rowsName: PropTypes.string.isRequired,
};

export default CustomTableFooter;
