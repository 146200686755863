import React, { useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import useAgencyTable from "hooks/tables/useAgencyTable";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useTranslation } from "react-i18next";
import {
  useAgenciesListQuery,
  useToggleStatusAgencyMutation,
} from "features/agency/agencyApiSlice";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import Filter from "components/Filter/Filter";
import AgencyFilter from "components/Filter/AgencyFilter/AgencyFilter";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { FilterSearchContainer } from "./AdminPage.styled";
import Title from "components/Title/Title";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useDispatch, useSelector } from "react-redux";
import modalConstants from "constants/modalConstants";
import { closeModal } from "features/modal/modalSlice";

const AdminPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modals = useSelector((state) => state.modal.modals);
  const toggleModal = modals?.[modalConstants.TOGGLE_AGENCY_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);

  const { columns } = useAgencyTable({ page, rowsPerPage });

  const [toggleStatusAgency, { isLoading: isLoadingToggleStatus }] =
    useToggleStatusAgencyMutation();

  const { data: agencies, isLoading } = useAgenciesListQuery({
    page,
    rowsPerPage,
    sort,
    filter,
  });

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const filterForm = useFormik({
    initialValues: { Active: "", AgencyTypeId: "", Name: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    dispatch(closeModal({ id: modalConstants.TOGGLE_AGENCY_MODAL }));
  };

  const handleConfirm = () => {
    if (toggleModal?.open) {
      toggleStatusAgency({
        id: toggleModal.data.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingToggleStatus) {
            handleCancel();
            makeToastMessage(t("agency.toggleSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("agency.toggleErrorMessage")));
    }
  };

  return (
    <PageContainer>
      <ConfirmModal
        open={toggleModal?.open}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        btnConfirm={
          toggleModal?.open &&
          toggleModal?.data.agencyActive
            ? "error"
            : "primary"
        }
        description={t("agency.confirmAgencyStatus", {
          status:
            toggleModal?.open &&
            toggleModal?.data.agencyActive
              ? t("agency.deactivateAgency")
              : t("agency.activateAgency"),
        })}
      />
      <Title title="Agencije" />
      <PageHeader>
        <FilterSearchContainer>
          <Filter
            filterForm={filterForm}
            handleReset={() => {
              setFilter(null);
              filterForm.resetForm();
            }}
          >
            <AgencyFilter filterForm={filterForm} />
          </Filter>
        </FilterSearchContainer>
        <Button
          variant="contained"
          onClick={() => navigate(PAGES.ADDAGENCY.route)}
        >
          {t("agency.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={agencies?.data}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("agency.rowsName")}
        isLoading={isLoading}
        count={agencies?.totalCount}
      />
    </PageContainer>
  );
};

export default AdminPage;
