import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openModal } from "features/modal/modalSlice";
import { PAGES } from "constants/pages";
import modalConstants from "constants/modalConstants";

const useReceiptsTable = ({ page, rowsPerPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "date",
      label: "Datum priznanice",
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return format(new Date(rowData.date), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "receiptNumber",
      label: "Broj priznanice",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contractNumber",
      label: "Broj ugovora",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "client",
      label: "Klijent",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalAmount",
      label: "Ukupan iznos",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    navigate(PAGES.EDITRECEIPT.route.replace(":id", rowData.id));
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_RECEIPT_MODAL,
                        data: { id: rowData.id },
                      })
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useReceiptsTable;
