/* eslint-disable react/prop-types */
import CountrySelect from "components/Inputs/CountrySelect";
import DatePick from "components/Inputs/DatePick";
import LocationSelect from "components/Inputs/LocationSelect";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { INTERNAL_CONTRACT } from "constants/contractState";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";
import { useAccommodationDropdownQuery } from "features/accommodation/accommodationApiSlice";
import { useAccommodationTypeDropdownQuery } from "features/accommodationType/accommodationTypeApiSlice";
import { useAccommodationUnitTypeDropdownQuery } from "features/accommodationUnitType/accommodationUnitTypeApiSlice";
import { useCategoryDropdownQuery } from "features/category/categoryApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useServiceDropdownQuery } from "features/service/serviceApiSlice";
import { useTransportationTypeDropdownQuery } from "features/transportationType/transportationTypeApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const TravelDetailsForm = ({ formik, offerDetailsLevelId, type }) => {
  const { t } = useTranslation();
  const { data: categories, isLoading: isLoadingCategories } =
    useCategoryDropdownQuery();
  const { data: offerTypes, isLoading: isLoadingOfferTypes } =
    useOfferTypeDropdownQuery();
  const { data: accommodationTypes, isLoading: isLoadingAccommodationTypes } =
    useAccommodationTypeDropdownQuery();
  const { data: accommodations, isLoading: isLoadingAccommodations } =
    useAccommodationDropdownQuery({});
  const { data: countries, isLoading: isLoadingCountries } =
    useCountryDropdownQuery();
  const { data: locations, isLoading: isLoadingLocations } =
    useLocationDropdownQuery(
      { id: formik.values.countryId },
      {
        skip: !formik.values?.countryId,
      }
    );
  const { data: services, isLoading: isLoadingServices } =
    useServiceDropdownQuery();
  const { data: transportationTypes, isLoading: isLoadingTransportationType } =
    useTransportationTypeDropdownQuery();
  const {
    data: accommodationUnitTypes,
    isLoading: isLoadingAccommodationUnitTypes,
  } = useAccommodationUnitTypeDropdownQuery();
  return (
    <>
      <RowContainer>
        <SelectInput
          fullWidth
          name="categoryId"
          label={t("field.categoryPlaceholderRequired")}
          onChange={formik.handleChange}
          value={formik.values?.categoryId || ""}
          items={!isLoadingCategories && categories}
          style={{ marginRight: "24px" }}
          disabled={type === INTERNAL_CONTRACT}
          error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
          helperText={formik.touched.categoryId && formik.errors.categoryId}
        />

        <SelectInput
          fullWidth
          name="offerTypeId"
          label={t("field.offerTypePlaceholderRequired")}
          onChange={formik.handleChange}
          value={formik.values?.offerTypeId || ""}
          items={!isLoadingOfferTypes && offerTypes}
          style={{ marginRight: "24px" }}
          disabled={type === INTERNAL_CONTRACT}
          error={
            formik.touched.offerTypeId && Boolean(formik.errors.offerTypeId)
          }
          helperText={formik.touched.offerTypeId && formik.errors.offerTypeId}
        />
        <CountrySelect
          fullWidth
          name="countryId"
          label={t("field.countryIdPlaceholder")}
          onChange={formik.handleChange}
          value={formik.values?.countryId || ""}
          items={!isLoadingCountries && countries}
          style={{ marginRight: "24px" }}
          disabled={type === INTERNAL_CONTRACT}
          error={formik.touched.countryId && Boolean(formik.errors.countryId)}
          helperText={formik.touched.countryId && formik.errors.countryId}
        />
        <SelectInput
          fullWidth
          name="appointmentDate"
          label={t("field.appointmentDatePlaceholder")}
          onChange={formik.handleChange}
          value={formik.values?.appointmentDate || ""}
          items={[]}
          error={
            formik.touched.appointmentDate &&
            Boolean(formik.errors.appointmentDate)
          }
          helperText={
            formik.touched.appointmentDate && formik.errors.appointmentDate
          }
        />
      </RowContainer>
      <RowContainer>
        <LocationSelect
          fullWidth
          name="locationId"
          disabled={type === INTERNAL_CONTRACT}
          label={t("field.locationIdPlaceholder")}
          onChange={formik.handleChange}
          value={formik.values?.locationId || ""}
          items={!isLoadingLocations && locations}
          countryId={formik.values.countryId}
          style={{ marginRight: "24px" }}
          error={formik.touched.locationId && Boolean(formik.errors.locationId)}
          helperText={formik.touched.locationId && formik.errors.locationId}
        />
        {offerDetailsLevelId === null && (
          <>
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          </>
        )}
        {offerDetailsLevelId === TRANSPORT && (
          <>
            <SelectInput
              fullWidth
              name="transportationTypeId"
              label={t("field.transportatopnTypeIdPlaceholderRequired")}
              onChange={formik.handleChange}
              value={formik.values?.transportationTypeId || ""}
              items={!isLoadingTransportationType && transportationTypes}
              disabled={type === INTERNAL_CONTRACT}
              error={
                formik.touched.transportationTypeId &&
                Boolean(formik.errors.transportationTypeId)
              }
              helperText={
                formik.touched.transportationTypeId &&
                formik.errors.transportationTypeId
              }
            />
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          </>
        )}
        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <>
            <SelectInput
              fullWidth
              name="accommodationTypeId"
              label={t("field.accommodationTypeIdPlaceholder")}
              value={formik.values?.accommodationTypeId || ""}
              onChange={formik.handleChange}
              items={!isLoadingAccommodationTypes && accommodationTypes}
              style={{ marginRight: "24px" }}
              disabled={type === INTERNAL_CONTRACT}
              error={
                formik.touched.accommodationTypeId &&
                Boolean(formik.errors.accommodationTypeId)
              }
              helperText={
                formik.touched.accommodationTypeId &&
                formik.errors.accommodationTypeId
              }
            />
            <SelectInput
              fullWidth
              name="accommodationId"
              label={t("field.accommodationIdPlaceholderRequired")}
              onChange={formik.handleChange}
              value={formik.values?.accommodationId || ""}
              items={!isLoadingAccommodations && accommodations}
              disabled={type === INTERNAL_CONTRACT}
              error={
                formik.touched.accommodationId &&
                Boolean(formik.errors.accommodationId)
              }
              helperText={
                formik.touched.accommodationId && formik.errors.accommodationId
              }
            />
          </>
        )}
        <DatePick
          name="departureDate"
          style={{ marginLeft: "24px" }}
          label={t("field.departureDatePlaceholder")}
          value={formik.values?.departureDate}
          onChange={(value) => formik.setFieldValue("departureDate", value)}
          error={
            formik.touched.departureDate && Boolean(formik.errors.departureDate)
          }
          helperText={
            formik.touched.departureDate && formik.errors.departureDate
          }
          minDate={new Date()}
        />
      </RowContainer>

      {(offerDetailsLevelId === ARRANGEMENT ||
        offerDetailsLevelId === ACCOMMODATION) && (
        <RowContainer>
          <SelectInput
            fullWidth
            name="accommodationUnitTypeId"
            label={t("field.accommodationUnitTypeRequiredPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values?.accommodationUnitTypeId || ""}
            items={!isLoadingAccommodationUnitTypes && accommodationUnitTypes}
            style={{ marginRight: "24px" }}
            disabled={type === INTERNAL_CONTRACT}
            error={
              formik.touched.accommodationUnitTypeId &&
              Boolean(formik.errors.accommodationUnitTypeId)
            }
            helperText={
              formik.touched.accommodationUnitTypeId &&
              formik.errors.accommodationUnitTypeId
            }
          />
          <SelectInput
            fullWidth
            name="serviceId"
            label={t("field.serviceIdPlaceholderRequired")}
            onChange={formik.handleChange}
            value={formik.values?.serviceId || ""}
            items={!isLoadingServices && services}
            style={{ marginRight: "24px" }}
            disabled={type === INTERNAL_CONTRACT}
            error={formik.touched.serviceId && Boolean(formik.errors.serviceId)}
            helperText={formik.touched.serviceId && formik.errors.serviceId}
          />
          {(offerDetailsLevelId === ARRANGEMENT ||
            offerDetailsLevelId === TRANSPORT) && (
            <>
              <SelectInput
                fullWidth
                name="transportationTypeId"
                label={t("field.transportatopnTypeIdPlaceholderRequired")}
                onChange={formik.handleChange}
                value={formik.values?.transportationTypeId || ""}
                items={!isLoadingTransportationType && transportationTypes}
                disabled={type === INTERNAL_CONTRACT}
                style={{ marginRight: "24px" }}
                error={
                  formik.touched.transportationTypeId &&
                  Boolean(formik.errors.transportationTypeId)
                }
                helperText={
                  formik.touched.transportationTypeId &&
                  formik.errors.transportationTypeId
                }
              />
            </>
          )}
          {(offerDetailsLevelId === ACCOMMODATION ||
            offerDetailsLevelId === ARRANGEMENT) && (
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          )}
          {offerDetailsLevelId === ACCOMMODATION && (
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "24px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          )}
        </RowContainer>
      )}
    </>
  );
};

export default TravelDetailsForm;
