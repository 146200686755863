/* eslint-disable react/prop-types */
import { Typography } from "@mui/material";
import React from "react";

const Title = ({ title, variant = "h4" }) => {
  return (
    <Typography variant={variant} style={{ marginBottom: 24 }}>
      {title}
    </Typography>
  );
};

export default Title;
