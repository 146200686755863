import { apiSlice } from "features/api/apiSlice";

export const agencyApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Agency", "AgencySingle", "AgencyLogo", "AgencySignature", "AgencyDropdown"],
  endpoints: (builder) => ({
    agencyDetails: builder.query({
      query: ({ id }) => ({
        url: `/agency/${id}`,
      }),
      providesTags: ["AgencySingle"],
    }),
    agencyDropdown: builder.query({
      query: (args) => {
        const { excludePartnerAgencies } = args;
        return {
          url:
            "/agency/dropdown?" +
            new URLSearchParams({
              ...(excludePartnerAgencies ? { excludePartnerAgencies } : {})
            })
        };
      },
      providesTags: ["AgencyDropdown"],
    }),
    agenciesList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, sort, filter } = args;
        return {
          url:
            "/agency/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              // Conditionally add to params object if keys exist
              //   ...(sort && { sortBy: sort }),
              //   ...(sortDirection && { orderBy: sortDirection }),
              ...(filter && filter),
            }),
        };
      },
      providesTags: ["Agency"],
    }),
    addAgency: builder.mutation({
      query: (data) => ({
        url: "/agency/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Agency"],
    }),
    editAgency: builder.mutation({
      query: (data) => ({
        url: "/agency/edit",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["AgencySingle", "Agency"],
    }),
    getAgencyLogo: builder.query({
      query: ({ agencyId }) => ({
        url: "/agency/logo?agencyId=" + agencyId,
      }),
      providesTags: ["AgencyLogo"],
    }),
    getAgencySignature: builder.query({
      query: ({ agencyId }) => ({
        url: "/agency/signature?agencyId=" + agencyId,
      }),
      providesTags: ["AgencySignature"],
    }),
    addLogo: builder.mutation({
      query: (data) => ({
        url: "/agency/add-logo",
        method: "POST",
        body: data,
      }),
    }),
    addSignature: builder.mutation({
      query: (data) => ({
        url: "/agency/add-signature",
        method: "POST",
        body: data,
      }),
    }),
    deleteAgencyLogo: builder.mutation({
      query: (data) => ({
        url: "/agency/delete-logo",
        method: "DELETE",
        body: data,
      }),
    }),
    deleteSignature: builder.mutation({
      query: (data) => ({
        url: "/agency/delete-signature",
        method: "DELETE",
        body: data,
      }),
    }),
    toggleStatusAgency: builder.mutation({
      query: (data) => ({
        url: "/agency/toggle-status",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Agency"],
    }),
  }),
});

export const {
  useAgencyDetailsQuery,
  useAgencyDropdownQuery,
  useGetAgencyLogoQuery,
  useGetAgencySignatureQuery,
  useAddLogoMutation,
  useAddSignatureMutation,
  useAgenciesListQuery,
  useAddAgencyMutation,
  useEditAgencyMutation,
  useDeleteAgencyLogoMutation,
  useDeleteSignatureMutation,
  useToggleStatusAgencyMutation,
} = agencyApiSlice;
