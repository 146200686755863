import React from "react";
import { codebookPageNavigationLinks } from "constants/navigationConstants";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import { CoodebookPageCardContainer } from "./CodebookPage.styled";
import PageCard from "components/Cards/PageCard/PageCard";

const CodebookPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Title title={t("pages.codebook")} />
      <CoodebookPageCardContainer>
        {codebookPageNavigationLinks.map((link) => (
          <PageCard key={link.title} path={link.path} Icon={link.icon}>
            {link.title}
          </PageCard>
        ))}
      </CoodebookPageCardContainer>
    </PageContainer>
  );
};

export default CodebookPage;
