import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { OTP_TYPE } from "constants/agencyType";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import {
  phoneNumberChars,
  preventNonNumericChars,
} from "util/helpers/preventNonNumericChars";
import FormActions from "../FormActions/FormActions";
import DatePick from "components/Inputs/DatePick";
import { useAgencyTypeQuery } from "features/agency-type/agencyTypeApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";

const AddAgencyForm = ({ formik }) => {
  const { t } = useTranslation();
  const { data: agencyTypes } = useAgencyTypeQuery();
  const { data: countries } = useCountryDropdownQuery();
  const { data: locations } = useLocationDropdownQuery(
    { id: formik.values.countryId },
    { skip: !formik.values.countryId }
  );
  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="name"
          label={t("field.agencyNamePlaceholder")}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          style={{ marginRight: "24px" }}
        />
        <SelectInput
          fullWidth
          name="agencyTypeId"
          label={t("field.agencyTypeIdPlaceholder")}
          onChange={formik.handleChange}
          value={formik.values.agencyTypeId}
          error={
            formik.touched.agencyTypeId && Boolean(formik.errors.agencyTypeId)
          }
          helperText={formik.touched.agencyTypeId && formik.errors.agencyTypeId}
          items={agencyTypes}
        />
      </RowContainer>

      <RowContainer>
        <TextInput
          fullWidth
          name="address"
          label={t("field.addressPlaceholder")}
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          style={{ marginRight: "24px" }}
        />

        <SelectInput
          fullWidth
          name="countryId"
          label={t("field.countryIdPlaceholder")}
          onChange={formik.handleChange}
          value={formik.values.countryId}
          error={formik.touched.countryId && Boolean(formik.errors.countryId)}
          helperText={formik.touched.countryId && formik.errors.countryId}
          style={{ marginRight: "24px" }}
          items={countries}
        />
        <SelectInput
          fullWidth
          name="locationId"
          disabled={!formik.values.countryId}
          label={t("field.locationIdPlaceholder")}
          onChange={formik.handleChange}
          value={formik.values.locationId}
          error={formik.touched.locationId && Boolean(formik.errors.locationId)}
          helperText={formik.touched.locationId && formik.errors.locationId}
          items={locations}
        />
      </RowContainer>

      <RowContainer>
        <TextInput
          fullWidth
          name="email"
          label={t("field.emailPlaceholder")}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          name="phoneNumber"
          fullWidth
          label={t("field.phoneNumberRequiredPlaceholder")}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
          }
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          onKeyDown={phoneNumberChars}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          fullWidth
          name="webAddress"
          label={t("field.webAddressPlaceholder")}
          value={formik.values.webAddress}
          onChange={formik.handleChange}
        />
      </RowContainer>

      <RowContainer>
        <TextInput
          fullWidth
          name="pib"
          label={t("field.pibPlaceholder")}
          value={formik.values.pib}
          onChange={formik.handleChange}
          error={formik.touched.pib && Boolean(formik.errors.pib)}
          helperText={formik.touched.pib && formik.errors.pib}
          onKeyDown={preventNonNumericChars}
          style={{ marginRight: "24px" }}
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 9);
          }}
        />
        <TextInput
          fullWidth
          name="legalIdentificationNumber"
          label={t("field.legalIdentificationNumberPlaceholderRequired")}
          value={formik.values.legalIdentificationNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.legalIdentificationNumber &&
            Boolean(formik.errors.legalIdentificationNumber)
          }
          helperText={
            formik.touched.legalIdentificationNumber &&
            formik.errors.legalIdentificationNumber
          }
          onKeyDown={preventNonNumericChars}
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 8);
          }}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          fullWidth
          name="personInCharge"
          label={t("field.personInChargePlaceholder")}
          value={formik.values.personInCharge}
          onChange={formik.handleChange}
        />
      </RowContainer>

      {formik.values.agencyTypeId === OTP_TYPE && (
        <RowContainer>
          <TextInput
            fullWidth
            name="licenceNumber"
            label={t("field.licenceNumberPlaceholder")}
            value={formik.values.licenceNumber}
            style={{ marginRight: "24px" }}
            onChange={formik.handleChange}
            error={
              formik.touched.licenceNumber &&
              Boolean(formik.errors.licenceNumber)
            }
            helperText={
              formik.touched.licenceNumber && formik.errors.licenceNumber
            }
          />
          <DatePick
            name="licenceNumberExpirationDate"
            label={t("field.licenceNumberExpirationDatePlaceholder")}
            value={formik.values.licenceNumberExpirationDate}
            onChange={(value) =>
              formik.setFieldValue(
                "licenceNumberExpirationDate",
                new Date(value),
                true
              )
            }
            error={
              formik.touched.licenceNumberExpirationDate &&
              Boolean(formik.errors.licenceNumberExpirationDate)
            }
            helperText={
              formik.touched.licenceNumberExpirationDate &&
              formik.errors.licenceNumberExpirationDate
            }
          />
        </RowContainer>
      )}

      <FormActions
        disabled={!formik.dirty}
        handleConfirm={formik.handleSubmit}
        handleCancel={formik.resetForm}
      />
    </Box>
  );
};
AddAgencyForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
};
export default AddAgencyForm;
