import styled from "styled-components";
import { RowContainer } from "styles/SharedStyle.styled";

export const FormActionsContainer = styled(RowContainer)`
  justify-content: flex-end;
  @media (max-width: 600px) {
    margin-top: 24px;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }
`;
