import {
  useAddOfferMutation,
  useEditOfferMutation,
  useOfferDetailsQuery,
} from "features/offer/offerApiSlice";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import { valueUpdate } from "./offerHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useSelector } from "react-redux";
import { selectCurrentAgency } from "features/auth/authSlice";
import offerInitialValues from "initialValues/offerInitialValues";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";
import arrangementOfferValidation from "validations/arrangementOfferValidation";
import accommodationOfferValidation from "validations/accommodationOfferValidation";
import transportationOfferValidation from "validations/transportationOfferValidation";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import OfferForm from "components/Forms/OfferForm/OfferForm";
import Title from "components/Title/Title";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";

const OfferAddEditPage = () => {
  const agencyId = useSelector(selectCurrentAgency);
  const { t } = useTranslation();
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDOFFER.route;
  const navigate = useNavigate();
  const [createOffer, { isLoading: isLoadingCreateOffer }] =
    useAddOfferMutation();
  const [editOffer, { isLoading: isLoadingEditOffer }] = useEditOfferMutation();
  const {
    data: offerDetails,
    isLoading: isLoadingOfferDetails,
    error,
  } = useOfferDetailsQuery({ id }, { skip: isAdd });
  const { data: offerTypes } = useOfferTypeDropdownQuery();

  const [offerDetailsLevelId, setOfferDetailsLevelId] = useState(
    id ? offerDetails?.offerTypeId : ""
  );

  const handleSubmit = async (values) => {
    if (isAdd) {
      createOffer(valueUpdate(values, offerDetailsLevelId, agencyId))
        .unwrap()
        .then(() => {
          if (!isLoadingCreateOffer) {
            navigate(PAGES.OFFERS.route);
            makeToastMessage(t("offers.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("offers.addErrorMessage")));
    }

    if (id) {
      editOffer({
        ...valueUpdate(values, offerDetailsLevelId),
        id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingEditOffer) {
            navigate(PAGES.OFFERS.route);
            makeToastMessage(t("offers.editSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("offers.editErrorMessage")));
    }
  };

  const validationSchemas = {
    [ARRANGEMENT]: arrangementOfferValidation,
    [ACCOMMODATION]: accommodationOfferValidation,
    [TRANSPORT]: transportationOfferValidation,
  };
  const formik = useFormik({
    initialValues:
      !isLoadingOfferDetails && id
        ? { ...offerDetails, travelOrganizerId: agencyId }
        : { ...offerInitialValues, travelOrganizerId: agencyId },
    validationSchema:
      offerDetailsLevelId !== null
        ? validationSchemas[offerDetailsLevelId]
        : arrangementOfferValidation,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values?.offerTypeId && !isLoadingOfferDetails) {
      setOfferDetailsLevelId(
        offerTypes?.find((item) => item.value === formik.values.offerTypeId)
          ?.offerDetailsLevelId
      );
    } else {
      setOfferDetailsLevelId(null);
    }
  }, [formik]);

  if (isLoadingOfferDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingOfferDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }
  return (
    <PageContainer>
      <Title title={id ? t("offers.editTitle") : t("offers.addTitle")} />
      <OfferForm formik={formik} offerDetailsLevelId={offerDetailsLevelId} />
    </PageContainer>
  );
};

export default OfferAddEditPage;
