import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import i18n from "../i18nt/index";

export function formatDate(date, fmt = "MM/dd/y", locale = enUS) {
  const dt = new Date(date);
  return format(dt, fmt, { locale });
}

export function formatDateString(dateString) {
  const date = new Date(dateString);

  // Get the day, month, and year components from the date object
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0, so we add 1
  const year = date.getFullYear();

  // Concatenate the components with dots in the desired format
  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
}


export function formatDateForFilter(date) {
     if (!date) return null;
     const d = new Date(date);
     const year = d.getFullYear();
     const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
     const day = String(d.getDate()).padStart(2, '0');
     return `${year}-${month}-${day}T00:00:00`;
}

export function formatDateTime(date) {
  const dt = new Date(date);
  return format(dt, "MM/dd/y hh:mm aa");
}

export function getDateDay(date) {
  const dt = new Date(date);
  return format(dt, "dd");
}

export function getDateMonth(date) {
  const dt = new Date(date);
  return format(dt, "MM");
}

export function getDateYear(date) {
  const dt = new Date(date);
  return format(dt, "y");
}

export function formatDateTimeLocale(date) {
  const dt = new Date(date);
  return format(dt, "MM/dd/y hh:mm aa");
}

export function formatToIsoDate(dateString) {
  // Create a new Date object without considering the time zone
  let date = new Date(dateString);

  // Adjust the date based on the time zone offset
  date.setHours(date.getHours() + 1); // Adding one hour for GMT+0100

  // Get the ISO string in UTC format
  var isoDate = date.toISOString();

  // Output the ISO date
  return isoDate;
}
export function convertDateToISO(date) {
  return new Date(
    new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000
  ).toISOString();
}

export function getOneDayBeforeDate(date) {
  const dt = new Date(date);
  dt.setDate(dt.getDate() - 1);
  return dt;
}

export const isAdult = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  const age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }
  return age;
};

// TODO add locale
export function formatDateRange(dates) {
  const start = formatDate(dates.start);
  const end = formatDate(dates.end);
  return i18n.t("common.date.range", { start, end });
}

export const calculateTimeSpan = (date) => {
  let currentDate = new Date();
  let timeElapsed = (currentDate.getTime() - date?.getTime()) / 1000;
  if (timeElapsed <= 0) {
    return {
      yearsPassed: 0,
      monthsPassed: 0,
      daysPassed: 0,
      hoursPassed: 0,
      minutesPassed: 0,
      secondsPassed: 0,
    };
  }

  const yearsPassed = Math.floor(timeElapsed / (60 * 60 * 24 * 365));
  timeElapsed -= yearsPassed * (60 * 60 * 24 * 365);

  const monthsPassed = Math.floor(timeElapsed / (60 * 60 * 24 * 31));
  timeElapsed -= monthsPassed * (60 * 60 * 24 * 31);

  const daysPassed = Math.floor(timeElapsed / (60 * 60 * 24));
  timeElapsed -= daysPassed * (60 * 60 * 24);

  const hoursPassed = Math.floor(timeElapsed / (60 * 60));
  timeElapsed -= hoursPassed * (60 * 60);

  const minutesPassed = Math.floor(timeElapsed / 60);
  timeElapsed -= minutesPassed * 60;

  const secondsPassed = Math.floor(timeElapsed);
  timeElapsed -= secondsPassed;

  return {
    yearsPassed,
    monthsPassed,
    daysPassed,
    hoursPassed,
    minutesPassed,
    secondsPassed,
  };
};

export const formatTimeSpan = (date) => {
  const timespanObject = calculateTimeSpan(date);
  if (timespanObject.yearsPassed > 0)
    return i18n.t("date.timespan.yearsAgo", {
      years: timespanObject.yearsPassed,
    });
  if (timespanObject.monthsPassed > 0)
    return i18n.t("date.timespan.monthsAgo", {
      months: timespanObject.monthsPassed,
    });
  if (timespanObject.daysPassed > 0)
    return i18n.t("date.timespan.daysAgo", {
      days: timespanObject.daysPassed,
    });
  if (timespanObject.hoursPassed > 0)
    return i18n.t("date.timespan.hoursAgo", {
      hours: timespanObject.hoursPassed,
    });
  if (timespanObject.minutesPassed > 0)
    return i18n.t("date.timespan.minutesAgo", {
      minutes: timespanObject.minutesPassed,
    });
  if (timespanObject.secondsPassed > 0)
    return i18n.t("date.timespan.secondsAgo", {
      seconds: timespanObject.secondsPassed,
    });
  return i18n.t("date.timespan.now");
};
