import { Box } from "@mui/system";
import styled from "styled-components";

export const UserSettingsFormContainer = styled(Box)`
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const UserSettingsBasicInfroContainer = styled(Box)`
  flex: 1;
  margin-right: 24px;
  @media (max-width: 960px) {
    margin-right: 0;
  }
`;
