import React from "react";
import TabsComponent from "components/Tab/Tabs";
import AgencySettingsForm from "./AgencySettingsForm";
import ListAddUser from "components/List/ListAddUser";
import { useSelector } from "react-redux";
import { selectCurrentAgency } from "features/auth/authSlice";
import ListAddLicence from "components/List/ListAddLicence";
import { PageContainer } from "styles/SharedStyle.styled";
import { useTranslation } from "react-i18next";
import PrintTab from "./PrintTab";
import { useLocation } from "react-router-dom";
import ListAddBankAccount from "components/List/ListAddBankAccount";

const SettingsPage = () => {
  const { t } = useTranslation();
  let { state } = useLocation();
  const tabId = state?.tabId;
  const agencyId = useSelector(selectCurrentAgency);
  const tabs = [
    {
      label: t("settings.tabs.agencyInfo"),
      component: <AgencySettingsForm id={agencyId} />,
    },
    {
      label: t("settings.tabs.agencyBankAccount"),
      component: <ListAddBankAccount id={agencyId} />,
    },
    {
      label: t("settings.tabs.users"),
      component: <ListAddUser id={agencyId} />,
    },
    {
      label: t("settings.tabs.licences"),
      component: <ListAddLicence id={agencyId} />,
    },
    {
      label: t("settings.tabs.print"),
      component: <PrintTab id={agencyId} />,
    },
  ];

  return (
    <PageContainer>
      <TabsComponent tabs={tabs} tabId={tabId} />
    </PageContainer>
  );
};

export default SettingsPage;
