import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import PropTypes from "prop-types";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
const BankAccountForm = ({ formik, isAdd }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("bankAccount.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="bankName"
            label={t("field.bankNamePlaceholderRequired")}
            value={formik.values.bankName || ""}
            onChange={formik?.handleChange}
            error={formik.touched.bankName && Boolean(formik.errors.bankName)}
            helperText={formik?.touched.bankName && formik?.errors.bankName}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="accountNumber"
            label={t("field.accountNumberPlaceholderRequired")}
            value={formik?.values.accountNumber || ""}
            onChange={formik.handleChange}
            error={
              formik.touched.accountNumber &&
              Boolean(formik.errors.accountNumber)
            }
            helperText={
              formik.touched.accountNumber && formik.errors.accountNumber
            }
            style={{ marginRight: "24px" }}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  name="isMain"
                  checked={formik.values.isMain}
                  value={formik.values.isMain}
                  onChange={formik.handleChange}
                />
              }
              label={t("field.isMainPlaceholder")}
            />
          </FormGroup>
        </RowContainer>
      </FormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleCancel={() => formik.resetForm()}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};
BankAccountForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    setFieldError: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string,
  isAdd: PropTypes.bool.isRequired,
};

export default BankAccountForm;
