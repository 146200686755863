import { Box } from "@mui/material";
import styled from "styled-components";

export const CardContainer = styled(Box)`
  cursor: pointer;
  border: 1px solid #377cbe;
  background: linear-gradient(
    to bottom,
    #7ebbe3 0%,
    #7ebbe3 3px,
    #8ec8e9 7px,
    #a1d7f1 15px,
    #c3f3ff 30px,
    #ffffff 50px,
    #ffffff 100%
  );
  box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.15),
    0px 2px 4px rgba(16, 24, 40, 0.08);
  width: 480px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 10px;
  border-radius: 20px;
  padding-left: 20px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;