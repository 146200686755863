/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { usePartnerTypeDropdownQuery } from "features/partnerType/partnerTypeApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const PartnerFilter = ({ filterForm }) => {
  const { t } = useTranslation();
  const partnerStatuses = [
    { value: true, text: t("dropdown.activePartner") },
    { value: false, text: t("dropdown.inactivePartner") },
  ];
  const { data: partnerTypes } =
  usePartnerTypeDropdownQuery();
  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="Name"
          label={t("field.partnerPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Name}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          fullWidth
          name="PartnerTypeId"
          label={t("field.partnerTypePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.PartnerTypeId}
          items={partnerTypes}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          fullWidth
          name="IsStatusActive"
          label={t("field.partnerStatusPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.IsStatusActive}
          items={partnerStatuses}
          InputLabelProps={{
            shrink: filterForm.values.IsStatusActive !== "",
          }}
        />
      </RowContainer>
    </Box>
  );
};

export default PartnerFilter;
