import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useFormik } from "formik";
import useOffersTable from "hooks/tables/useOffersTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { getNonEmptyProps } from "util/helpers/objectHelpers";

import {
  useOfferListQuery,
  useDeleteOfferMutation,
  useToggleStatusOfferMutation,
} from "features/offer/offerApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import Filter from "components/Filter/Filter";
import OfferFilter from "components/Filter/OfferFilter/OfferFilter";
import { useNavigate } from "react-router-dom";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import { useDispatch, useSelector } from "react-redux";
import modalConstants from "constants/modalConstants";
import { closeModal } from "features/modal/modalSlice";

const OffersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modals = useSelector((state) => state.modal.modals);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const toggleModal = modals?.[modalConstants.TOGGLE_OFFER_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_OFFER_MODAL];
  const { columns } = useOffersTable({
    page,
    rowsPerPage,
  });
  const { data: offerList, isLoading: isLoadingOfferList } = useOfferListQuery({
    page,
    rowsPerPage,
    sort,
    filter,
  });
  const [toggleStatusOffer, { isLoading: isLoadingToggleStatusOffer }] =
    useToggleStatusOfferMutation();

  const [deleteOffer, { isLoading: isLoadingDeleteOffer }] =
    useDeleteOfferMutation();

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const filterForm = useFormik({
    initialValues: {
      OfferTypeId: "",
      AccommodationId: "",
      TransportationTypeId: "",
      ServiceId: "",
      CategoryId: "",
      Active: "",
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCancelConfirm = () => {
    if (deleteModal?.open) {
      dispatch(closeModal({ id: modalConstants.DELETE_OFFER_MODAL }));
    }
    if (toggleModal?.open) {
      dispatch(closeModal({ id: modalConstants.TOGGLE_OFFER_MODAL }));
    }
  };

  const handleConfirm = () => {
    if (deleteModal?.open) {
      deleteOffer({ id: deleteModal.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteOffer) {
            dispatch(closeModal({ id: modalConstants.DELETE_OFFER_MODAL }));
            makeToastMessage(t("offers.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("offers.deleteErrorMessage")));
    }

    if (toggleModal?.open) {
      toggleStatusOffer({
        id: toggleModal.data.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingToggleStatusOffer) {
            dispatch(closeModal({ id: modalConstants.TOGGLE_OFFER_MODAL }));
            makeToastMessage(t("offers.toggleStatusOfferSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("offers.toggleStatusOfferErrorMessage"))
        );
    }
  };

  return (
    <PageContainer>
      <ConfirmModal
        open={deleteModal?.open || toggleModal?.open}
        handleCancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={
          toggleModal?.open
            ? t("offers.confirmOfferStatus", {
                status: toggleModal.data.offerActive
                  ? t("offers.deactivateOffer")
                  : t("offers.activateOffer"),
              })
            : deleteModal?.open
            ? t("offers.confirmDelete")
            : ""
        }
        btnConfirm={
          (toggleModal?.open && toggleModal?.data.offerActive) ||
          deleteModal?.open
            ? "error"
            : "primary"
        }
      />
      <Title title={t("offers.pageTitle")} />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <OfferFilter filterForm={filterForm} />
        </Filter>
        <Button
          variant="contained"
          onClick={() => navigate(PAGES.ADDOFFER.route)}
        >
          {t("offers.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={offerList?.data}
        isLoading={isLoadingOfferList}
        count={offerList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("offers.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default OffersPage;
