import { Box } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import { useLicenceStatusDropdownQuery } from "features/licenceStatus/licenceStatusApiSlice";

const LicenceForm = ({ formik, edit }) => {
  const { data: licenceStatuses } = useLicenceStatusDropdownQuery();

  const { t } = useTranslation();
  return (
    <Box component="form">
      {edit && (
        <RowContainer>
          <TextInput
            fullWidth
            disabled
            name="number"
            // label={t("field.systemLicenceNumberPlaceholder")}
            //onChange={formik.handleChange}
            value={formik.values.number || ""}
          />
        </RowContainer>
      )}
      <RowContainer>
        <DatePick
          name="startDate"
          label={t("field.systemLicenceStartDatePlaceholder")}
          value={formik.values.startDate}
          onChange={(value) => formik.setFieldValue("startDate", value, true)}
          error={formik.touched.startDate && Boolean(formik.errors.startDate)}
          helperText={formik.touched.startDate && formik.errors.startDate}
        />
      </RowContainer>
      <RowContainer>
        <DatePick
          name="endDate"
          label={t("field.systemLicenceEndDatePlaceholder")}
          value={formik.values.endDate}
          onChange={(value) => formik.setFieldValue("endDate", value, true)}
          // minDate={
          //   edit ? subYears(new Date(formatedValues?.endDate), 1) : new Date()
          // }
          // maxDate={
          //   edit
          //     ? addYears(new Date(formatedValues?.endDate), 2)
          //     : addYears(new Date(), 2)
          // }

          error={formik.touched.endDate && Boolean(formik.errors.endDate)}
          helperText={formik.touched.endDate && formik.errors.endDate}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          fullWidth
          name="licenceStatusId"
          label={t("field.systemLicenceStatusIdPlaceholder")}
          onChange={formik.handleChange}
          value={formik.values.licenceStatusId || ""}
          error={
            formik.touched.licenceStatusId &&
            Boolean(formik.errors.licenceStatusId)
          }
          helperText={
            formik.touched.licenceStatusId && formik.errors.licenceStatusId
          }
          items={licenceStatuses}
        />
      </RowContainer>

      <FormActions
        disabled={!formik.dirty}
        handleCancel={formik.resetForm}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};
LicenceForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
  edit: PropTypes.bool,
};

export default LicenceForm;
