import BackdropComponent from "components/Backdrop/BackdropComponent";
import LicenceForm from "components/Forms/LicenceForm/LicenceForm";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import {
  useAddLicenceMutation,
  useEditLicenceMutation,
  useLicenceDetailsQuery,
} from "features/licence/licenceApiSlice";
import { useFormik } from "formik";
import systemLicenceInitialValues from "initialValues/systemLicenceInitialValues";
import Error from "pages/ErrorPage/ErrorPage";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import systemLicenceValidation from "validations/systemLicenceValidation";

const AddEditLicencePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  let { state } = useLocation();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDLICENCE.route;
  const navigate = useNavigate();
  const [addLicence] = useAddLicenceMutation();
  const [editLicence] = useEditLicenceMutation();
  const {
    data: licenceDetails,
    isLoadingLicenceDetails,
    error,
  } = useLicenceDetailsQuery({ id: id }, { skip: isAdd });

  const handleSubmit = async (values) => {
    if (isAdd) {
      addLicence({
        agencyId: Number(state?.addAgencyId),
        licenceStatusId: Number(values.licenceStatusId),
        startDate: convertDateToISO(values.startDate),
        endDate: convertDateToISO(values.endDate),
      })
        .unwrap()
        .then(() => {
          makeToastMessage(t("licence.addSuccessMessage"));
          navigate(PAGES.AGENCY.route.replace(":id", state?.addAgencyId), {
            state: { tabId: 2 },
          });
        })
        .catch(() => makeErrorToastMessage(t("licence.addErrorMessage")));
    }

    if (id) {
      editLicence({
        agencyId: Number(state?.editAgencyId),
        id: id,
        licenceStatusId: Number(values.licenceStatusId),
        startDate: convertDateToISO(values.startDate),
        endDate: convertDateToISO(values.endDate),
      })
        .unwrap()
        .then(() => {
          makeToastMessage(t("licence.editSuccessMessage"));
          navigate(PAGES.AGENCY.route.replace(":id", state?.editAgencyId), {
            state: { tabId: 2 },
          });
        })
        .catch(() => makeErrorToastMessage(t("licence.editErrorMessage")));
    }
  };

  const formik = useFormik({
    initialValues:
      id && !isLoadingLicenceDetails
        ? {
            ...licenceDetails,
            startDate: new Date(licenceDetails?.startDate),
            endDate: new Date(licenceDetails?.endDate),
          }
        : systemLicenceInitialValues,
    validationSchema: systemLicenceValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingLicenceDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingLicenceDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <PageContainer>
      <Title title={id ? t("licence.editTitle") : t("licence.addTitle")} />
      <LicenceForm formik={formik} edit={!isAdd} />
    </PageContainer>
  );
};

export default AddEditLicencePage;
