/* eslint-disable no-unused-vars */
import { Box, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import Filter from "components/Filter/Filter";
import InternalSaleFilter from "components/Filter/InternalSaleFilter/InternalSaleFilter";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import PreviewInternalSaleModal from "components/Modals/PreviewInternalSale/PreviewInternalSaleModal";
import {
  useOfferDetailsQuery,
  useSaleQuery,
} from "features/offer/offerApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useFormik } from "formik";
import useInternalSaleTable from "hooks/tables/useInternalSaleTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { useNavigate } from "react-router-dom";
import { INTERNAL_CONTRACT } from "constants/contractState";

const InternalSalePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [openReserve, setOpenReserve] = useState(false);
  const [offerDetailsLevelId, setOfferDetailsLevelId] = useState(null);

  const { columns } = useInternalSaleTable({
    setSelectedId,
    page,
    rowsPerPage,
    setOpenPreview,
    setOpenReserve,
  });

  const { data: sales, isLoading: isLoadingSales } = useSaleQuery({
    page,
    rowsPerPage,
    sort,
    filter,
  }, { refetchOnMountOrArgChange: true });

  const { data: offerDetails, isLoading: isLoadingOfferDetails } =
    useOfferDetailsQuery(
      { id: selectedId },
      { skip: !selectedId || !openPreview }
    );

  const { data: offerTypes } = useOfferTypeDropdownQuery(
    {},
    { skip: !openPreview }
  );

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const formik = useFormik({
    initialValues: openPreview && !isLoadingOfferDetails && offerDetails,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: {
      TravelOrganizerId: "",
      CountryId: "",
      OfferTypeId: "",
      LocationId: "",
      AccommodationTypeId: "",
      AccommodationId: "",
      AccommodationUnitTypeId: "",
      ServiceId: "",
      TransportationTypeId: "",
      Active: "",
      UnitPropertyId: "",
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.offerTypeId && !isLoadingOfferDetails) {
      setOfferDetailsLevelId(
        offerTypes?.find((item) => item.value === formik.values.offerTypeId)
          ?.offerDetailsLevelId
      );
    } else {
      setOfferDetailsLevelId(null);
    }
  }, [formik.values.offerTypeId, isLoadingOfferDetails]);

  const handleCancelConfirm = () => {
    setSelectedId(null);
    setOpenReserve(false);
  };

  const handleConfirm = () => {
    navigate(`/contract/${INTERNAL_CONTRACT}`, {
      state: { type: INTERNAL_CONTRACT, id: selectedId },
    });
  };

  const handleClosePreview = () => {
    setSelectedId(null);
    setOpenPreview(false);
  };

  return (
    <PageContainer>
      <ConfirmModal
        open={openReserve}
        handleCancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("internalSale.reserveConfirmation")}
        btnConfirm="primary"
      />
      <PreviewInternalSaleModal
        open={openPreview}
        formik={formik}
        offerDetailsLevelId={offerDetailsLevelId}
        handleClose={handleClosePreview}
        handleConfirm={handleConfirm}
        title={t("internalSale.preview")}
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("internalSale.pageTitle")}
      </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <InternalSaleFilter filterForm={filterForm} />
        </Filter>
        <Box />
      </PageHeader>
      <CustomTable
        columns={columns}
        data={sales?.data}
        isLoading={isLoadingSales}
        count={sales?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("internalSale.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default InternalSalePage;
