import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import useLicenceTable from "hooks/tables/useLicenceTable";
import { useTranslation } from "react-i18next";
import { useLicenceListQuery } from "features/licence/licenceApiSlice";
import { ButtonContainer, ContentContainer } from "styles/SharedStyle.styled";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const ListAddLicence = ({ id }) => {
  const navigate = useNavigate();
  const { isSuperAdmin } = useAuth();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const { data: listLicences, isLoading: isLoadingLicenceList } =
    useLicenceListQuery({ agencyId: id, page, rowsPerPage, sort });
  const { columns } = useLicenceTable({
    page,
    rowsPerPage,
  });

  return (
    <ContentContainer>
      {isSuperAdmin && (
        <ButtonContainer>
          <Button
            variant="contained"
            onClick={() =>
              navigate(PAGES.ADDLICENCE.route, { state: { addAgencyId: Number(id) } })
            }
          >
            {t("licence.addButton")}
          </Button>
        </ButtonContainer>
      )}
      <CustomTable
        columns={columns}
        data={listLicences?.data}
        isLoading={isLoadingLicenceList}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("licence.rowsName")}
        count={listLicences?.totalCount}
      />
    </ContentContainer>
  );
};
ListAddLicence.propTypes = {
  id: PropTypes.string.isRequired,
};
export default ListAddLicence;
