import { convertDateToISO } from "util/dateHelpers";

export function getNonEmptyProps(obj) {
  const nonEmptyProps = {};
  for (const prop in obj) {
    if (obj[prop] !== "" && obj[prop] !== null) {
      if (typeof obj[prop] === "object") {
        nonEmptyProps[prop] = convertDateToISO(obj[prop]);
      }
      nonEmptyProps[prop] = obj[prop];
    }
  }

  return nonEmptyProps;
}