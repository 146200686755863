import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import modalConstants from "constants/modalConstants";
import { closeModal, openModal } from "features/modal/modalSlice";
import {
  useAddServiceMutation,
  useDeleteServiceMutation,
  useEditServiceMutation,
  useServiceListQuery,
} from "features/service/serviceApiSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const ServicesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const { columns } = useCodebookTable({
    page,
    rowsPerPage,
  });

  const debouncedSearch = useDebounce(search);

  const { data: services, isLoading } = useServiceListQuery({
    page,
    rowsPerPage,
    search: debouncedSearch,
    sort,
  });
  const [addService, { isLoading: isLoadingAddService }] =
    useAddServiceMutation();

  const [editService, { isLoading: isLoadingEditService }] =
    useEditServiceMutation();

  const [deleteService, { isLoading: isLoadingDeleteService }] =
    useDeleteServiceMutation();

  const handleSubmit = (val) => {
    if (addModal?.open) {
      const { name } = val;
      addService({ name })
        .unwrap()
        .then(() => {
          if (!isLoadingAddService) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
            makeToastMessage(t("services.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("services.addErrorMessage")));
    }

    if (editModal?.open) {
      const { id, name } = val;

      editService({ name, id })
        .unwrap()
        .then(() => {
          if (!isLoadingEditService) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
            makeToastMessage(t("services.editSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("services.editErrorMessage")));
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteService({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteService) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("services.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("services.deleteErrorMessage")));
    }
  };

  const formik = useFormik({
    initialValues: editModal?.open ? editModal?.data : { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.servicesRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("services.editTitle")}
        addTitle={t("services.addTitle")}
        labelTitle={t("field.servicesPlaceholder")}
        confirmCloseDescription={t("services.confirmClose")}
        confirmDeleteDescription={t("services.confirmDelete")}
        handleConfirmDelete={handleConfirmDelete}
      />

      <Title title={t("services.pageTitle")} />
      <PageHeader>
        <Search
          search={search}
          handleChange={handleSearch}
          label={t("services.searchPlaceholder")}
        />
        <Button variant="contained" onClick={handleOpenAdd}>
          {t("services.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={services?.data}
        isLoading={isLoading}
        count={services?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("services.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ServicesPage;
