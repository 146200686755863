import { Box, IconButton } from "@mui/material";
import styled from "styled-components";

export const HeaderIconContainer = styled(IconButton)`
  cursor: pointer;
  min-width: 0;
  border-radius: 100%;
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.primaryLighter};
  }
  & svg {
    fill: ${(props) => props?.theme?.colors?.textColor};
  }
`;
export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  height: 72px;
`;

export const HeaderSideContainer = styled(Box)`
  display: flex;
  gap: 24px;
  height: 100%;
  align-items: center;
`;
