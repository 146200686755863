/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const StatementFilter = ({ filterForm }) => {
  const { t } = useTranslation();
  return (
    <Box component="form">
      <RowContainer>
        <DatePick
          name="Date"
          label={t("field.dateFilter")}
          selected={
            filterForm.values.Date ? new Date(filterForm.values.Date) : null
          }
          value={filterForm.values.Date}
          onChange={(value) => filterForm.setFieldValue("Date", value)}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="AccountId"
          label={t("field.accountNumberFilter")}
          onChange={filterForm.handleChange}
          fullWidth
          value={filterForm.values.AccountId}
          items={[]}
        />
      </RowContainer>
      <RowContainer>
        <TextInput
          fullWidth
          name="StatementNumber"
          label={t("field.statementNumberFilter")}
          onChange={filterForm.handleChange}
          value={filterForm.values.StatementNumber}
        />
      </RowContainer>
    </Box>
  );
};

export default StatementFilter;
