import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  number: Yup.string().required(i18next.t("field.invoiceNumberRequired")),
  date: Yup.date()
    .required(i18next.t("field.invoiceDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  location: Yup.string().required(
    i18next.t("field.invoiceLocationRequired")
  ),
  transactionDate: Yup.date()
    .required(i18next.t("field.invoiceTransactionDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  dueDate: Yup.date()
    .required(i18next.t("field.invoiceDueDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  currencyId: Yup.string().required(i18next.t("field.nbsCurrencyRequired")),
  clientPartner: Yup.number().required(
    i18next.t("field.clientPartnerRequired")
  ),
  totalAmount: Yup.string().required(i18next.t("field.invoiceTotalAmount")),
  items: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(
        i18next.t("field.invoiceDescriptionRequired")
      ),
      price: Yup.string().required(i18next.t("field.invoicePriceRequired")),
      quantity: Yup.number()
        .required(i18next.t("field.invoiceQuantityRequired"))
        .min(1, i18next.t("field.invoiceQuantityMin")),
      total: Yup.string().required(i18next.t("field.invoiceTotalRequired")),
    })
  ),
});