import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ClientFilter from "components/Filter/ClientFilter/ClientFilter";
import Filter from "components/Filter/Filter";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import Title from "components/Title/Title";
import modalConstants from "constants/modalConstants";
import {
  useClientListQuery,
  useDeleteClientMutation,
} from "features/client/clientApiSlice";
import { closeModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import useClientTable from "hooks/tables/useClientTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";

const ClientsPage = () => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "surname", direction: "asc" });
  const [filter, setFilter] = useState(null);
  const { columns } = useClientTable({
    page,
    rowsPerPage,
  });
  const modal = modals?.[modalConstants.DELETE_CLIENT_MODAL];
  const { data: clientList, isLoading: isLoadingClientList } =
    useClientListQuery({
      page,
      rowsPerPage,
      search,
      sort,
      filter,
    });
  const [deleteClient, { isLoading: isLoadingDeleteUser }] =
    useDeleteClientMutation();
  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };
  const filterForm = useFormik({
    initialValues: { Name: "", Surname: "", CountryId: "", LocationId: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const handleClose = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_CLIENT_MODAL }));
  };
  const handleConfirmDelete = () => {
    if (modal?.open) {
      deleteClient({ id: modal.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteUser) {
            handleClose();
            makeToastMessage(t("client.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("client.deleteErrorMessage")));
    }
  };
  return (
    <PageContainer>
      <ConfirmModal
        open={modal?.open}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleClose}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("client.confirmDelete")}
      />
      <Title title={t("client.pageTitle")} />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <ClientFilter filterForm={filterForm} />
        </Filter>
        <Button variant="contained" onClick={() => navigate("/clients/add")}>
          {t("client.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={clientList?.data}
        isLoading={isLoadingClientList}
        count={clientList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSearch={setSearch}
        rowsName={t("client.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ClientsPage;
