/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { phoneNumberChars } from "util/helpers/preventNonNumericChars";
import TextInput from "components/Inputs/TextInput";
import DatePick from "components/Inputs/DatePick";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import SelectInput from "components/Inputs/SelectInput";
import { genders } from "constants/genderConstants";

const AddClientModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  edit = false,
  title,
}) => {
  const { t } = useTranslation();

  const formatedValues = {
    dateOfBirth: edit ? new Date(formik.values.dateOfBirth) : null,
    passportExpirationDate:
      edit && formik?.values?.passportExpirationDate
        ? new Date(formik.values.passportExpirationDate)
        : null,
  };

  const { data: countries } = useCountryDropdownQuery({}, { skip: !open });
  const { data: locations } = useLocationDropdownQuery(
    { id: formik.values.countryId },
    { skip: !open || !formik.values.countryId }
  );

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="surname"
            label={t("field.surnamePlaceholder")}
            value={formik?.values?.surname}
            onChange={formik?.handleChange}
            error={formik?.touched?.surname && Boolean(formik?.errors?.surname)}
            helperText={formik?.touched?.surname && formik?.errors?.surname}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="name"
            label={t("field.namePlaceholder")}
            value={formik?.values?.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: "24px" }}
          />
          <SelectInput
            fullWidth
            name="genderId"
            label={t("field.genderIdPlaceholder")}
            value={formik.values?.genderId}
            onChange={formik.handleChange}
            error={formik.touched.genderId && Boolean(formik.errors.genderId)}
            helperText={formik.touched.genderId && formik.errors.genderId}
            items={genders}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="address"
            label={t("field.addressPlaceholder")}
            value={formik?.values?.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            style={{ marginRight: "24px" }}
          />

          <CountrySelect
            fullWidth
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik?.values?.countryId}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            style={{ marginRight: "24px" }}
            items={countries}
          />
          <LocationSelect
            fullWidth
            name="locationId"
            disabled={!formik.values.countryId}
            label={t("field.locationIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik?.values?.locationId}
            error={
              formik.touched.locationId && Boolean(formik.errors.locationId)
            }
            helperText={formik.touched.locationId && formik.errors.locationId}
            items={locations}
            countryId={formik.values.countryId}
          />
        </RowContainer>

        <RowContainer>
          <DatePick
            name="dateOfBirth"
            label={t("field.dateOfBirthPlaceholder")}
            value={formatedValues?.dateOfBirth}
            onChange={(value) =>
              formik.setFieldValue("dateOfBirth", value, true)
            }
            error={
              formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
            }
            helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberPlaceholder")}
            value={formik?.values?.phoneNumber}
            onChange={formik.handleChange}
            style={{ marginRight: "24px" }}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
          />
          <TextInput
            fullWidth
            name="email"
            label={t("field.emailNoRequiredPlaceholder")}
            value={formik?.values?.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </RowContainer>

        <RowContainer>
            <TextInput
              fullWidth
              name="passportNumber"
              label={t("field.passportNumberPlaceholder")}
              value={formik?.values?.passportNumber}
              onChange={formik.handleChange}
              style={{ marginRight: 24 }}
              error={
                formik.touched.passportNumber &&
                Boolean(formik.errors.passportNumber)
              }
              helperText={
                formik.touched.passportNumber && formik.errors.passportNumber
              }
            />
            <DatePick
              minDate={new Date()}
              name="passportExpirationDate"
              label={t("field.passportExpirationDatePlaceholder")}
              value={formatedValues?.passportExpirationDate}
              onChange={(value) =>
                formik.setFieldValue("passportExpirationDate", value, true)
              }
              error={
                formik.touched.licenceNumberExpirationDate &&
                Boolean(formik.errors.licenceNumberExpirationDate)
              }
              helperText={
                formik.touched.licenceNumberExpirationDate &&
                formik.errors.licenceNumberExpirationDate
              }
            />
        </RowContainer>
        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddClientModal;
