/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Dialog, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSwipeable } from "react-swipeable";

const ImageCarousel = ({ open, handleClose, images, initialIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images?.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images?.length - 1 ? prevIndex + 1 : 0
    );
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
  });

  useEffect(() => {
    if (initialIndex !== null) {
      setCurrentIndex(initialIndex);
    }
  }, [initialIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrevious();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const currentImageDetails = images ? images[currentIndex] : null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ background: "transparent" }}
    >
      <Box
        sx={{
          opacity: open ? 1 : 0,
          position: "relative",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8, color: "black" }}
        >
          <CloseIcon />
        </IconButton>
        {images.length > 1 && (
          <IconButton
            onClick={handlePrevious}
            sx={{ position: "absolute", top: "50%", left: 8, color: "black" }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}
        {images.length > 1 && (
          <IconButton
            onClick={handleNext}
            sx={{ position: "absolute", top: "50%", right: 8, color: "black" }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
        <Box
          {...swipeHandlers}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "60%",
            height: "60%",
            touchAction: "none", // to ensure swipe gestures are recognized
          }}
        >
          <img
            src={currentImageDetails}
            alt={currentImageDetails}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ImageCarousel;
