/* eslint-disable react/prop-types */
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { useExchangeRateDropdownQuery } from "features/exchange-rate/exchangeRateSlice";
import { RowContainer } from "styles/SharedStyle.styled";
import TextInput from "components/Inputs/TextInput";
import DatePick from "components/Inputs/DatePick";
import SelectInput from "components/Inputs/SelectInput";
import { validateNumericInput } from "util/helpers/preventNonNumericChars";

const AddExchangeRateModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  edit = false,
  title,
}) => {
  const { t } = useTranslation();

  const { data: currencies } = useExchangeRateDropdownQuery();

  const newDate = new Date(formik?.values?.date);

  const isValidInput = (value) => {
    const regex = /^(?!.*\..*\.)[1-9][0-9]*(\.[0-9]{0,4})?$/;
    return regex.test(value);
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginBottom: "24px" }}
      >
        {edit ? t("exchangeRate.editTitle") : t("exchangeRate.addTitle")}
      </Typography>
      <Box component="form">
        <RowContainer>
          <DatePick
            name="date"
            disabled
            label={t("field.dateCalendar")}
            value={newDate}
            onChange={(value) => formik.setFieldValue("date", value, true)}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
        </RowContainer>
        <SelectInput
          fullWidth
          name="currencyId"
          label={t("field.currencyPlaceholderRequired")}
          value={formik?.values?.currencyId}
          onChange={formik.handleChange}
          onKeyDown={validateNumericInput}
          items={currencies}
          error={formik.touched.currencyId && Boolean(formik.errors.currencyId)}
          helperText={formik.touched.currencyId && formik.errors.currencyId}
          style={{ marginBottom: "24px" }}
        />
        <RowContainer>
          <TextInput
            fullWidth
            name="rate"
            type="number"
            label={t("field.middleCourse")}
            value={formik?.values?.rate}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                formik.setFieldValue("rate", parseFloat(inputValue));
              }
            }}
            error={formik.touched.rate && Boolean(formik.errors.rate)}
            helperText={formik.touched.rate && formik.errors.rate}
            style={{ marginBottom: "24px" }}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddExchangeRateModal;
