/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import DatePick from "components/Inputs/DatePick";
import TextInput from "components/Inputs/TextInput";

const InvoiceFilter = ({ filterForm, isProInvoice }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="Number"
          label={
            isProInvoice
              ? t("field.proInvoiceNumberFilter")
              : t("field.invoiceNumberFilter")
          }
          onChange={filterForm.handleChange}
          value={filterForm.values.Number}
          style={{ marginRight: 24 }}
        />
        <DatePick
          name="OnDate"
          label={
            isProInvoice
              ? t("field.proInvoiceDateFilter")
              : t("field.invoiceDateFilter")
          }
          selected={
            filterForm.values.OnDate ? new Date(filterForm.values.OnDate) : null
          }
          value={filterForm.values.OnDate}
          onChange={(value) => filterForm.setFieldValue("OnDate", value)}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="ClientId"
          label={t("field.clientPartnerFilter")}
          onChange={filterForm.handleChange}
          fullWidth
          value={filterForm.values.ClientId}
          items={[]}
        />
      </RowContainer>
    </Box>
  );
};

export default InvoiceFilter;
