export default {
  receiptNumber: "",
  date: null,
  clientId: "",
  contractNumber: "",
  description: "",
  cashAmount: "",
  cardAmount: "",
  checkAmount: "",
  totalAmount: "",
  dueAmount: "",
};
