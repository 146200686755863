import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ImageOverlayContainer = styled(Box)`
  animation: ${fadeIn} 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  border-radius: 8px;
  background: rgba(55, 124, 190, 0.8);
`;

export const TopRightButtonStyle = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
`;
export const OverlayTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
  line-height: 1.1;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
  @media (max-width: 959px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const CenterOverlayIcon = styled(Box)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  align-self: center;
`;

export const ZoomIconStyle = styled(ZoomOutMapIcon)`
  padding: 1px;
  border: 1px solid white;
  border-radius: 50%;
  font-size: 36px;
  color: white;
  &:hover: {
    color: black;
    border: 1px solid black;
  },
`;
