import { apiSlice } from "features/api/apiSlice";

export const clientApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Client", "ClientDropdown", "ClientSingle", "ClientPassportImage"],
  endpoints: (builder) => ({
    clientDropdown: builder.query({
      query: () => ({
        url: "/client/dropdown",
      }),
      providesTags: ["ClientDropdown"],
    }),
    clientList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort, filter } = args;
        return {
          url:
            "/client/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(filter && filter),
              ...(search && { Name: search }),
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
            }),
        };
      },
      providesTags: ["Client"],
    }),
    clientDetails: builder.query({
      query: ({ id }) => ({
        url: `/client/${id}`,
      }),
      providesTags: ["ClientSingle"],
    }),
    addClient: builder.mutation({
      query: (data) => ({
        url: "/client/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Client", "ClientDropdown"],
    }),
    editClient: builder.mutation({
      query: (data) => ({
        url: "/client/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Client", "ClientSingle"],
    }),
    deleteClient: builder.mutation({
      query: (data) => ({
        url: "/client",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Client"],
    }),
    getClientPassportImage: builder.query({
      query: ({ id }) => ({
        url: `/client/photo?clientId=${id}`,
      }),
      providesTags: ["ClientPassportImage"],
    }),

    uploadPassportImage: builder.mutation({
      query: (data) => ({
        url: "/client/add-photo",
        method: "POST",
        body: { ...data },
      })
    }),
    deletePassportImage: builder.mutation({
      query: (data) => ({
        url: "/client/delete-photo",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["ClientPassportImage"],
    }),
  }),
});

export const {
  useClientDropdownQuery,
  useClientListQuery,
  useClientDetailsQuery,
  useAddClientMutation,
  useEditClientMutation,
  useDeleteClientMutation,
  useGetClientPassportImageQuery,
  useUploadPassportImageMutation,
  useDeletePassportImageMutation,
} = clientApiSlice;
