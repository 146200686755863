import i18n from "../i18nt/index";

export const PAGES = {
  BASE: {
    route: "/",
    title: i18n.t("pages.home"),
  },
  HOME: {
    route: "/home",
    title: i18n.t("pages.home"),
  },
  ADDAGENCY: {
    route: "/agency/add",
    title: i18n.t("pages.agency"),
  },
  REGISTER: {
    route: "/register",
    title: i18n.t("pages.register"),
  },
  LOGIN: {
    route: "/login",
    title: i18n.t("pages.login"),
  },
  FORGOT_PASSWORD: {
    route: "/forgot-password",
    title: i18n.t("pages.forgotPassword"),
  },
  ERROR: {
    route: "/error-page",
    title: i18n.t("pages.error"),
  },
  NOT_FOUND: {
    route: "/not-found",
    title: i18n.t("pages.notFound"),
  },
  PROFILE: {
    route: "/profile",
    title: i18n.t("pages.profile"),
  },
  SETTINGS: {
    route: "/settings",
    title: i18n.t("pages.settings"),
  },
  DASHBOARD: {
    route: "/dashboard",
    title: i18n.t("pages.dashboard"),
  },
  CLIENTS: {
    route: "/clients",
    title: i18n.t("pages.clients"),
  },
  ADDCLIENT: {
    route: "/clients/add",
    title: i18n.t("pages.clients"),
  },
  EDITCLIENT: {
    route: "/clients/edit/:id",
    title: i18n.t("pages.clients"),
  },
  ADDPARTNER: {
    route: "/partners/add",
    title: i18n.t("pages.partners"),
  },
  PARTNERS: {
    route: "/partners",
    title: i18n.t("pages.partners"),
  },
  EDITPARTNER: {
    route: "/partners/edit/:id",
    title: i18n.t("pages.partners"),
  },
  ACCOMMODATION_UNIT: {
    route: "/accommodation/:id",
    title: i18n.t("pages.accommodationUnit"),
  },
  CODEBOOK: {
    route: "/codebook",
    title: i18n.t("pages.codebook"),
  },
  OFFERS: {
    route: "/offers",
    title: i18n.t("pages.offers"),
  },
  ADDOFFER: {
    route: "/offers/add",
    title: i18n.t("pages.offers"),
  },
  EDITOFFER: {
    route: "/offers/edit/:id",
    title: i18n.t("pages.offers"),
  },
  SALES: {
    route: "/sales",
    title: i18n.t("pages.sales"),
  },
  INTERNAL_SALE: {
    route: "/internal-sale",
    title: i18n.t("pages.internalSale"),
  },
  EXTERNAL_SALE: {
    route: "/contract/external",
    title: i18n.t("pages.externalSale"),
  },
  CONTRACTS: {
    route: "/contract",
    title: i18n.t("pages.contracts"),
  },
  SINGLE_CONTRACT: {
    route: "/contract/:id",
    title: i18n.t("pages.singleContract"),
  },
  PAYMENT: {
    route: "/payment",
    title: i18n.t("pages.payment"),
  },
  STATEMENTS: {
    route: "/statements",
    title: i18n.t("pages.excerpts"),
  },
  REPORTS: {
    route: "/reports",
    title: i18n.t("pages.reports"),
  },
  NOTIFICATIONS: {
    route: "/notifications",
    title: i18n.t("pages.notifications"),
  },
  ADMIN: {
    route: "/admin",
  },
  AGENCY: {
    route: "/agency/:id",
  },
  CATEGORY: {
    route: "/category",
    title: i18n.t("pages.travelCategory"),
  },
  COUNTRY: {
    route: "/country",
    title: i18n.t("pages.country"),
  },
  LOCATION: {
    route: "/location",
    title: i18n.t("pages.city"),
  },
  ACCOMMODATION_TYPE: {
    route: "/accommodation-type",
    title: i18n.t("pages.objectType"),
  },
  ACCOMMODATION: {
    route: "/accommodation",
    title: i18n.t("pages.object"),
  },
  ACCOMMODATION_UNIT_TYPE: {
    route: "/accommodation-unit-type",
    title: i18n.t("pages.accommodationUnitType"),
  },
  ACCOMMODATION_UNIT_PROPERTY: {
    route: "/unit-property",
    title: i18n.t("pages.accomodationUnitCharacteristics"),
  },
  SERVICES: {
    route: "/services",
    title: i18n.t("pages.serviceType"),
  },
  TRANSPORTATION_TYPE: {
    route: "/transportation-type",
    title: i18n.t("pages.transportationType"),
  },
  OFFER_TYPE: {
    route: "/offer-type",
    title: i18n.t("pages.offerType"),
  },
  RECEIPTS: {
    route: "/receipts",
    title: i18n.t("pages.receipts"),
  },
  INVOICE: {
    route: "/invoice",
    title: i18n.t("pages.invoice"),
  },
  PROINVOICE: {
    route: "/proinvoice",
    title: i18n.t("pages.proinvoice"),
  },
  EXCHANGE_RATE: {
    route: "/exchange-rate",
    title: i18n.t("pages.exchangeRate"),
  },
  EDITUSER: {
    route: "/settings/user/edit/:id",
    title: i18n.t("pages.editUser"),
  },
  ADDUSER: {
    route: "/settings/user/add",
    title: i18n.t("pages.addUser"),
  },
  EDITUSERADMIN: {
    route: "/user/edit/:id",
    title: i18n.t("pages.editUser"),
  },
  ADDUSERADMIN: {
    route: "/user/add",
    title: i18n.t("pages.addUser"),
  },
  ADDLICENCE: {
    route: "/licence/add",
    title: i18n.t("pages.addLicence"),
  },
  EDITLICENCE: {
    route: "/licence/edit/:id",
    title: i18n.t("pages.editLicence"),
  },
  ADDRECEIPT: {
    route: "/receipts/add",
    title: i18n.t("pages.addReceipt"),
  },
  EDITRECEIPT: {
    route: "/receipts/edit/:id",
    title: i18n.t("pages.editReceipt"),
  },
  ADDSTATEMENT: {
    route: "/statements/add",
    title: i18n.t("pages.addReceipt"),
  },
  EDITSTATEMENT: {
    route: "/statements/edit/:id",
    title: i18n.t("pages.editReceipt"),
  },
  ADDINVOICE: {
    route: "/invoice/add",
    title: i18n.t("pages.addInvoice"),
  },
  EDITINVOICE: {
    route: "/invoice/edit/:id",
    title: i18n.t("pages.editInvoice"),
  },
  ADDPROINVOICE: {
    route: "/proinvoice/add",
    title: i18n.t("pages.addProinvoice"),
  },
  EDITPROINVOICE: {
    route: "/proinvoice/edit/:id",
    title: i18n.t("pages.editProinvoice"),
  },
  ADDBANKACCOUNT: {
    route: "/bank-account/add",
    title: i18n.t("pages.addBankAccount"),
  },
  EDITBANKACCOUNT: {
    route: "/bank-account/edit/:id",
    title: i18n.t("pages.editBankAccount"),
  },
};
