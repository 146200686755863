import { Box } from "@mui/material";
import styled from "styled-components";

export const StatementItemRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

export const StatementOrdinalNumber = styled(Box)`
  align-self: flex-start;
  margin-right: 24px;
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 24px;
  }
`;
