import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import modalConstants from "constants/modalConstants";
import {
  useAccommodationDetailsQuery,
  useAccommodationListQuery,
  useAddAccommodationMutation,
  useDeleteAccommodationMutation,
  useEditAccommodationMutation,
} from "features/accommodation/accommodationApiSlice";
import { closeModal, openModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const AccommodationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const { columns } = useCodebookTable({
    type: "accommodation",
    page,
    rowsPerPage,
  });

  const debouncedSearch = useDebounce(search);

  const { data: accommodations, isLoading } = useAccommodationListQuery(
    {
      page,
      rowsPerPage,
      search: debouncedSearch,
      sort,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [addAccommodation, { isLoading: isLoadingAddAccommodation }] =
    useAddAccommodationMutation();

  const [editAccommodation, { isLoading: isLoadingEditAccommodation }] =
    useEditAccommodationMutation();

  const [deleteAccommodation, { isLoading: isLoadingDeleteAccommodation }] =
    useDeleteAccommodationMutation();

  const { data: singleAccommodation, isLoading: isLoadingSingleAccommodation } =
    useAccommodationDetailsQuery(
      { id: editModal?.data.id },
      { skip: !editModal?.open }
    );

  const handleSubmit = (val) => {
    if (addModal?.open) {
      addAccommodation(val)
        .unwrap()
        .then(() => {
          if (!isLoadingAddAccommodation) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodation.addSuccessMessage"));
          }
        })
        .catch((e) => makeErrorToastMessage(e.data.message));
    }

    if (editModal?.open) {
      editAccommodation(val)
        .unwrap()
        .then(() => {
          if (!isLoadingEditAccommodation) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodation.editSuccessMessage"));
          }
        })
        .catch((e) => makeErrorToastMessage(e.data.message));
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteAccommodation({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteAccommodation) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodation.deleteSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodation.deleteErrorMessage"))
        );
    }
  };

  const formik = useFormik({
    initialValues:
      editModal?.open && !isLoadingSingleAccommodation
        ? singleAccommodation
        : {
            name: "",
            countryId: null,
            locationId: null,
            accommodationTypeId: null,
          },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.accommodationRequired")),
      countryId: Yup.number().required(t("field.countryIdRequired")),
      locationId: Yup.number().required(t("field.locationIdRequired")),
      accommodationTypeId: Yup.number().required(
        t("field.accommodationTypeIdRequired")
      ),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("accommodation.editTitle")}
        addTitle={t("accommodation.addTitle")}
        labelTitle={t("field.accommodationPlaceholder")}
        confirmCloseDescription={t("accommodation.confirmClose")}
        confirmDeleteDescription={t("accommodation.confirmDelete")}
        handleConfirmDelete={handleConfirmDelete}
        type={"accommodation"}
      />
      <Title title={t("accommodation.pageTitle")} />
      <PageHeader>
        <Search
          label={t("accommodation.searchPlaceholder")}
          search={search}
          handleChange={handleSearch}
        />
        <Button variant="contained" onClick={handleOpenAdd}>
          {t("accommodation.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={accommodations?.data}
        isLoading={isLoading}
        count={accommodations?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("accommodation.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default AccommodationPage;
