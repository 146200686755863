import React, { useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import Filter from "components/Filter/Filter";
import { FilterSearchContainer } from "./PartnersPage.styled";
import usePartnersTable from "hooks/tables/usePartnersTable";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import PartnerFilter from "components/Filter/PartnerFilter/PartnerFilter";
import SelectInput from "components/Inputs/SelectInput";
import {
  useAgencyPartnerListQuery,
  useCreateExistingPartnerMutation,
  useDeleteExternalMutation,
  useDeleteExistingMutation,
} from "features/agencyPartner/agencyPartnerApiSlice";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";

const PartnersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const deletePartnerModal =
    modals?.[modalConstants.DELETE_PARTNER_MODAL];
  const addPartnerModal = modals?.[modalConstants.ADD_INTERNAL_PARTNER_MODAL];
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const { data: agencyPartnerList, isLoading: isLoadingAgencyPartnerList } =
    useAgencyPartnerListQuery({
      page,
      rowsPerPage,
      sort,
      filter,
    });

  const [deleteExternal, { isLoading: isLoadingDeleteExternal }] =
    useDeleteExternalMutation();

  const [deleteExisting, { isLoading: isLoadingDeleteExisting }] =
    useDeleteExistingMutation();

  const [createExistingPartner, { isLoading: isLoadingCreateExistingPartner }] =
    useCreateExistingPartnerMutation();

  const { columns } = usePartnersTable({
    page,
    rowsPerPage,
  });

  const { data: agencies } = useAgencyDropdownQuery({
    excludePartnerAgencies: true,
  });

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const filterForm = useFormik({
    initialValues: { PartnerTypeId: "", Name: "", IsStatusActive: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleConfirm = () => {
    if (addPartnerModal?.open) {
      createExistingPartner({ partnerId: addPartnerModal.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingCreateExistingPartner) {
            dispatch(
              closeModal({ id: modalConstants.ADD_INTERNAL_PARTNER_MODAL })
            );
            makeToastMessage(t("partners.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("partners.addErrorMessage")));
    }

    if (deletePartnerModal?.open) {
      if (deletePartnerModal?.data.isPartnerOnly) {
        deleteExternal({ id: deletePartnerModal.data.id })
          .unwrap()
          .then(() => {
            if (!isLoadingDeleteExternal) {
              dispatch(
                closeModal({ id: modalConstants.DELETE_PARTNER_MODAL })
              );
              makeToastMessage(t("partners.deleteSuccessMessage"));
            }
          })
          .catch(() => makeErrorToastMessage(t("partners.deleteErrorMessage")));
      } else {
        deleteExisting({ id: deletePartnerModal.data.id })
          .unwrap()
          .then(() => {
            if (!isLoadingDeleteExisting) {
              dispatch(
                closeModal({ id: modalConstants.DELETE_PARTNER_MODAL })
              );
              makeToastMessage(t("partners.deleteSuccessMessage"));
            }
          })
          .catch(() => makeErrorToastMessage(t("partners.deleteErrorMessage")));
      }
    }
  };

  const handleCancelConfirm = () => {
    if (addPartnerModal?.open) {
      dispatch(closeModal({ id: modalConstants.ADD_INTERNAL_PARTNER_MODAL }));
    }

    if (deletePartnerModal?.open) {
      dispatch(
        closeModal({ id: modalConstants.DELETE_PARTNER_MODAL })
      );
    }
  };

  return (
    <PageContainer>
      <ConfirmModal
        open={deletePartnerModal?.open || addPartnerModal?.open}
        description={
          deletePartnerModal?.open
            ? t("partners.confirmDeletePartner")
            : t("partners.addPartnerFromSystem")
        }
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancelConfirm}
        btnConfirm={deletePartnerModal?.open ? "error" : "primary"}
      />
      <Title title={t("partners.pageTitle")} />
      <PageHeader>
        <FilterSearchContainer>
          <Filter
            filterForm={filterForm}
            handleReset={() => {
              setFilter(null);
              filterForm.resetForm();
            }}
          >
            <PartnerFilter filterForm={filterForm} />
          </Filter>
        </FilterSearchContainer>
        <Box style={{ display: "flex", width: "35%", alignItems: "stretch" }}>
          <SelectInput
            fullWidth
            label={t("partners.dropdownPartnersList")}
            onChange={(e) => {
              dispatch(
                openModal({
                  id: modalConstants.ADD_INTERNAL_PARTNER_MODAL,
                  data: { id: e.target.value },
                })
              );
            }}
            value={addPartnerModal?.data?.id || ""}
            items={agencies}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate(PAGES.ADDPARTNER.route)}
            style={{ marginLeft: 24 }}
          >
            {t("partners.addButton")}
          </Button>
        </Box>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={agencyPartnerList?.data}
        count={agencyPartnerList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("partners.rowsName")}
        isLoading={isLoadingAgencyPartnerList}
      />
    </PageContainer>
  );
};

export default PartnersPage;
