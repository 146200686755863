/* eslint-disable no-unused-vars */
import BackdropComponent from "components/Backdrop/BackdropComponent";
import StatementForm from "components/Forms/StatementForm/StatementForm";
import StatementItemForm from "components/Forms/StatementForm/StatementItemForm";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import { DOMESTIC_TYPE, FOREIGN_TYPE } from "constants/statementType";
import {
  useAddStatementMutation,
  useStatementDetailsQuery,
} from "features/statement/statementApiSlice";
import { useAddStatementItemMutation } from "features/statementItem/statementItemApiSlice";
import { useFormik } from "formik";
import statementInitialValues from "initialValues/statementInitialValues";
import statementItemsInitialValues from "initialValues/statementItemsInitialValues";
import Error from "pages/ErrorPage/ErrorPage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import domesticStatementValidation from "validations/domesticStatementValidation";
import foreignStatementValidation from "validations/foreignStatementValidation";
import statementValidation from "validations/statementValidation";

const AddEditStatementPage = () => {
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDSTATEMENT.route;
  const { t } = useTranslation();
  const [statementId, setStatementId] = useState(null);

  const [addStatement, { isLoading: isLoadingAddStatement }] =
    useAddStatementMutation();
  const [addStatementItem, { isLoading: isLoadingAddStatementItem }] =
    useAddStatementItemMutation();
  const {
    data: statementDetails,
    isLoading: isLoadingStatementDetails,
    error,
  } = useStatementDetailsQuery(
    {
      id,
    },
    { skip: isAdd }
  );

  const handleStatementSubmit = async (values) => {
    if (id) {
      // edit statement
    }

    if (!id) {
      try {
        const statement = await addStatement({
          ...values,
          date: convertDateToISO(values.date),
        }).unwrap();

        if (statement && !isLoadingAddStatement) {
          setStatementId(statement);
        }
      } catch (err) {
        makeErrorToastMessage(err.data.message);
      }
    }
  };

  function getFields(dataArray, statementType) {
    return dataArray.map((data) => {
      const { clientPartner, ...rest } = data;
      let result = { ...rest };

      if (clientPartner === 1) {
        delete result.clientId;
      } else if (clientPartner === 2) {
        delete result.partnerId;
      }

      if (statementType === DOMESTIC_TYPE) {
        delete result.foreignAmount;
        delete result.exchangeRate;
        delete result.totalAmount;
      } else if (statementType === FOREIGN_TYPE) {
        delete result.amount;
      }

      return result;
    });
  }

  const handleStatementItemsSubmit = (values) => {
    if (isAdd) {
      const vals = getFields(
        values.items,
        formikStatement.values.statementTypeId
      );
      console.log(vals);
      addStatementItem(vals)
        .unwrap()
        .then(() => {
          makeToastMessage(t("statementItem.addSuccess"));
        })
        .catch((err) => {
          makeErrorToastMessage(err.data.message);
        });
    }
  };

  const formikStatement = useFormik({
    onSubmit: handleStatementSubmit,
    initialValues:
      id && !isLoadingStatementDetails
        ? { ...statementDetails, date: new Date(statementDetails.date) }
        : statementInitialValues,
    validationSchema: statementValidation,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const formikStatementItem = useFormik({
    onSubmit: handleStatementItemsSubmit,
    initialValues: statementItemsInitialValues,
    validationSchema:
      formikStatement?.values.statementTypeId === DOMESTIC_TYPE
        ? domesticStatementValidation
        : formikStatement?.values.statementTypeId === FOREIGN_TYPE
        ? foreignStatementValidation
        : null,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingStatementDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingStatementDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <PageContainer>
      <Title title={id ? t("statement.editTitle") : t("statement.addTitle")} />
      <StatementForm formik={formikStatement} statementId={statementId} />
      {(statementId || statementDetails?.id) && (
        <StatementItemForm
          formik={formikStatementItem}
          date={formikStatement.values.date}
          statementId={statementId}
          statementType={formikStatement.values.statementTypeId}
        />
      )}
    </PageContainer>
  );
};

export default AddEditStatementPage;
