/* eslint-disable react/prop-types */
import AddEditCodebookItemModal from "components/Modals/AddEditCodebookItemModal/AddEditCodebookItemModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import modalConstants from "constants/modalConstants";
import { closeModal, openModal } from "features/modal/modalSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, batch } from "react-redux";

const Codebooks = ({
  formik,
  editTitle,
  addTitle,
  labelTitle,
  confirmCloseDescription,
  confirmDeleteDescription,
  handleConfirmDelete,
  type,
}) => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const closeConfirmModal = modals?.[modalConstants.CLOSE_CONFIRM_DIALOG];
  const { t } = useTranslation();

  const handleCloseAddEdit = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (formik.dirty) {
        dispatch(openModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
      } else {
        if (addModal?.open) {
          formik.resetForm();
          dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
        } else {
          formik.resetForm();
          dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
        }
      }
    }
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      dispatch(openModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
    } else {
      if (addModal?.open) {
        formik.resetForm();
        dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
      } else {
        formik.resetForm();
        dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
      }
    }
  };

  const handleCancelDelete = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
  };

  const handleCloseConfirmDialogAdd = () => {
    formik.resetForm();
    batch(() => {
      dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
      dispatch(closeModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
    });
  };

  const handleCloseConfirmDialogEdit = () => {
    formik.resetForm();
    batch(() => {
      dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
      dispatch(closeModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
    });
  };

  const handleCancel = () => {
    dispatch(closeModal({ id: modalConstants.CLOSE_CONFIRM_DIALOG }));
  };

  return (
    <>
      <AddEditCodebookItemModal
        open={editModal?.open ? editModal?.open : addModal?.open}
        handleClose={handleCloseAddEdit}
        handleCloseBtn={handleCloseBtn}
        formik={formik}
        title={editModal?.open ? editTitle : addTitle}
        labelTitle={labelTitle}
        type={type}
      />
      <ConfirmCloseDialog
        open={closeConfirmModal?.open}
        handleClose={
          editModal?.open
            ? handleCloseConfirmDialogEdit
            : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={confirmCloseDescription}
      />
      <ConfirmModal
        open={deleteModal?.open}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={confirmDeleteDescription}
      />
    </>
  );
};

export default Codebooks;
