import React, { useEffect, useState } from "react";
import { PageContainer } from "styles/SharedStyle.styled";
import { useFormik } from "formik";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import externalSaleValidation from "validations/externalSaleValidation";
import ExternalContractForm from "components/Forms/ExternalContractForm/ExternalContractForm";
import externalSaleInitialValues from "initialValues/externalSaleInitialValues";

const ExternalSalePage = () => {
  const [offerDetailsLevelId, setOfferDetailsLevelId] = useState(null);
  const { data: offerTypes } = useOfferTypeDropdownQuery();

  const handleSubmit = (values) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: externalSaleInitialValues,
    validationSchema: externalSaleValidation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  useEffect(() => {
    if (formik.values?.offerTypeId) {
      setOfferDetailsLevelId(
        offerTypes?.find((item) => item.value === formik.values.offerTypeId)
          ?.offerDetailsLevelId
      );
    } else {
      setOfferDetailsLevelId(null);
    }
  }, [formik.values.offerTypeId]);


  return (
    <PageContainer>
      <ExternalContractForm
        formik={formik}
        offerDetailsLevelId={offerDetailsLevelId}
        handleCancel={() => formik.resetForm()}
      />
    </PageContainer>
  );
};

export default ExternalSalePage;
