/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import DatePick from "components/Inputs/DatePick";
import ClientSelect from "components/Inputs/ClientSelect";

const ReceiptsFilter = ({ filterForm }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="ReceiptNumber"
          label={t("field.receiptNumber")}
          style={{ marginRight: 24 }}
          onChange={filterForm.handleChange}
          value={filterForm.values.ReceiptNumber}
        />
        <TextInput
          fullWidth
          name="ContractNumber"
          label={t("field.contractNumberPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.ContractNumber}
        />
      </RowContainer>
      <RowContainer>
        <DatePick
          name="From"
          label={t("field.dateFrom")}
          value={filterForm.values.From}
          selected={filterForm.values.From ? new Date(filterForm.values.From) : null}
          onChange={(value) => filterForm.setFieldValue("From", value)}
          style={{ marginRight: 24 }}
        />
        <DatePick
          name="Till"
          label={t("field.dateTo")}
          selected={filterForm.values.Till ? new Date(filterForm.values.Till) : null}
          value={filterForm.values.Till}
          onChange={(value) => filterForm.setFieldValue("Till", value)}
        />
      </RowContainer>
      <RowContainer>
        <ClientSelect
          name="ClientId"
          label={t("field.client")}
          onChange={(event, newValue) => {
            filterForm.setFieldValue(
              "ClientId",
              newValue ? newValue.value : ""
            );
          }}
          value={filterForm.values.ClientId}
        />
      </RowContainer>
    </Box>
  );
};

export default ReceiptsFilter;
