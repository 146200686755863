import { apiSlice } from "features/api/apiSlice";

export const partnerTypeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["PartnerType"],
  endpoints: (builder) => ({
    partnerTypeDropdown: builder.query({
      query: () => ({
        url: "/partner-type/dropdown",
      }),
      providesTags: ["PartnerType"]
    }),
  }),
});

export const { usePartnerTypeDropdownQuery } = partnerTypeApiSlice;
