import { Box } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
} from "components/Forms/AgencyForm/AgencyForm.styled";
import FormActions from "components/Forms/FormActions/FormActions";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentContainer } from "styles/SharedStyle.styled";

const PrintTab = () => {
  const { t } = useTranslation();
  return (
    <ContentContainer component="form">
      <FormContainer style={{ marginBottom: 24 }}>
        <InformationContainer>
          <InformationMainText>
            {t("settings.printTab.info")}
          </InformationMainText>
        </InformationContainer>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <TextInput
            multiline
            minRows={5}
            label={t('settings.printTab.defaultTextForDocumentPriting')}
            style={{ 
              marginBottom: 24,
              marginTop: 5,
            }}
          />
          <TextInput
            multiline
            minRows={5}
            label={t('settings.printTab.defaultTextForContractNotes')}
            style={{ marginBottom: 24 }}
          />
          <TextInput
            multiline
            minRows={5}
            label={t('settings.printTab.defaultTextForInvoiceNotes')}
            style={{ marginBottom: 24 }}
          />
          <TextInput
            multiline
            minRows={5}
            label={t('settings.printTab.defaultTextForReceiptNotes')}
            style={{ marginBottom: 24 }}
          />
        </Box>
      </FormContainer>
      <FormActions
        disabled={true}
        handleCancel={() => {}}
        handleConfirm={() => {}}
      />
    </ContentContainer>
  );
};

export default PrintTab;
