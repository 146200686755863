import { Box } from "@mui/material";
import styled from "styled-components";

export const TableFooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const TableFooterRowsPerPage = styled(Box)`
  display: flex;
  align-items: center;
`;

export const TableFooterTotalCount = styled(Box)`
  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
`;

export const TableFooterPagination = styled(Box)`
  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
`;
