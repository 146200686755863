/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";

const useInvoiceTable = ({ page, rowsPerPage, isProInvoice }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "number",
      label: isProInvoice ? "Broj profakture" : "Broj fakture",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "date",
      label: isProInvoice ? "Datum profakture" : "Datum fakture",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return rowData.date
            ? format(new Date(rowData.date), "dd.MM.yyyy.")
            : "";
        },
      },
    },
    {
      name: "clientPartner",
      label: "Klijent(Partner)",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Iznos",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "amountChange",
      label: "Osnova za račun",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "updatedOn",
      label: "Poslednje izmene",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h7">
                Datum: {format(new Date(rowData.updatedOn), "dd.MM.yyyy.")}
              </Typography>
              <Typography variant="h7">
                Vreme: {format(new Date(rowData.updatedOn), "HH:mm")}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    isProInvoice
                      ? navigate(
                          PAGES.EDITPROINVOICE.route.replace(":id", rowData.id)
                        )
                      : navigate(
                          PAGES.EDITCLIENT.route.replace(":id", rowData.id)
                        );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: isProInvoice
                          ? modalConstants.DELETE_PROINVOICE_MODAL
                          : modalConstants.DELETE_INVOICE_MODAL,
                        data: { id: rowData.id },
                      })
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useInvoiceTable;
