/* eslint-disable react/prop-types */
import { Box, Chip } from "@mui/material";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Status = ({ active, label }) => {
  return (
    <Chip
      label={label}
      icon={
        <Box style={{ alignSelf: "center", paddingLeft: 8 }}>
          <FiberManualRecordIcon
            style={{
              width: 8,
              height: 8,
              color: active ? "#1CB948" : "#BF3F37",
            }}
          />
        </Box>
      }
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        color: active ? "#1CB948" : "#BF3F37",
        background: active ? "#F2FEF5" : "#FCECEB",
        width: "100%",
      }}
    />
  );
};

export default Status;
