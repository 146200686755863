import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import useAuth from "hooks/useAuth";

const useBankAccountTable = ({ page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isManager } = useAuth();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "bankName",
      label: "Naziv banke",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "accountNumber",
      label: "Broj bankovnog računa",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "isMain",
      label: "Podrazumevani račun",
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
            const bankAccount = arrayToObject(tableMeta.rowData, columns);
            return bankAccount.isMain && <CheckIcon />;

        }
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        display: isManager,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const bankAccount = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex" }}>
              <Tooltip
                title={"Izmeni bankovni račun"}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    navigate(
                      PAGES.EDITBANKACCOUNT.route.replace(":id", bankAccount.id)
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={"Obriši bankovni račun"}
                placement="top"
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_BANK_ACCOUNT_MODAL,
                        data: { id: bankAccount.id },
                      })
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useBankAccountTable;
