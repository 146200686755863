/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";

const useStatementTable = ({ page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "date",
      label: "Datum izvoda",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return format(new Date(rowData?.date), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "number",
      label: "Broj izvoda",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accountNumber",
      label: "Broj bankovnog računa",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "statementType",
      label: "Vrsta izvoda",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "updatedBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "updatedOn",
      label: "Poslednje izmene",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h7">
                Datum: {format(new Date(rowData.updatedOn), "dd.MM.yyyy.")}
              </Typography>
              <Typography variant="h7">Izmenio: {rowData.updatedBy}</Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const statement = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    navigate(
                      PAGES.EDITSTATEMENT.route.replace(":id", statement.id)
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_STATEMENT_MODAL,
                        data: {
                          id: statement.id,
                        },
                      })
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useStatementTable;
