import React, { useEffect, useState } from "react";
import { PageContainer } from "styles/SharedStyle.styled";
import ContractForm from "components/Forms/ContractForm/ContractForm";
import { useFormik } from "formik";
import { useOfferDetailsQuery } from "features/offer/offerApiSlice";
import { useLocation } from "react-router-dom";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import { INTERNAL_CONTRACT, EDIT_CONTRACT } from "constants/contractState";
import contractInitialValues from "initialValues/contractInitialValues";
import contractOfferInitialValues from "initialValues/contractOfferInitialValues";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";
import arrangementContractValidation from "validations/arrangementContractValidation";
import accommodationContractValidation from "validations/accommodationContractValidation";
import transportContractValidation from "validations/transportContractValidation";
import defaultContractValidation from "validations/defaultContractValidation";

const SingleContractPage = () => {
  let { state } = useLocation();
  const [offerDetailsLevelId, setOfferDetailsLevelId] = useState(null);
  const { data: offerDetails, isLoading: isLoadingOfferDetails } =
    useOfferDetailsQuery(
      { id: state?.id },
      {
        skip:
          !state?.id &&
          (state?.type !== INTERNAL_CONTRACT || state?.type !== EDIT_CONTRACT),
      }
    );
  const { data: offerTypes } = useOfferTypeDropdownQuery();

  const handleSubmit = (values) => {
    console.log(values);
  };

  const validationSchemas = {
    [ARRANGEMENT]: arrangementContractValidation,
    [ACCOMMODATION]: accommodationContractValidation,
    [TRANSPORT]: transportContractValidation,
  };

  const formik = useFormik({
    initialValues:
      !isLoadingOfferDetails &&
      (state?.type === INTERNAL_CONTRACT || state?.type === EDIT_CONTRACT)
        ? {
            ...offerDetails,
            ...contractInitialValues,
          }
        : { ...contractOfferInitialValues, ...contractInitialValues },
    validationSchema:
      offerDetailsLevelId !== null
        ? validationSchemas[offerDetailsLevelId]
        : defaultContractValidation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  useEffect(() => {
    if (formik.values?.offerTypeId && !isLoadingOfferDetails) {
      setOfferDetailsLevelId(
        offerTypes?.find((item) => item.value === formik.values.offerTypeId)
          ?.offerDetailsLevelId
      );
    } else {
      setOfferDetailsLevelId(null);
    }
  }, [formik.values.offerTypeId, isLoadingOfferDetails]);

  if (isLoadingOfferDetails) {
    <BackdropComponent position="absolute" isLoading={isLoadingOfferDetails} />;
  }

  return (
    <PageContainer>
      <ContractForm
        formik={formik}
        offerDetailsLevelId={offerDetailsLevelId}
        type={state?.type}
        handleCancel={() => formik.resetForm()}
      />
    </PageContainer>
  );
};

export default SingleContractPage;
