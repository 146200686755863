import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  statementTypeId: Yup.string().required(
    i18next.t("field.statementTypeRequired")
  ),
  number: Yup.string().required(
    i18next.t("field.statementNumberRequireduired")
  ),
  date: Yup.date()
    .required(i18next.t("field.statementDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  accountNumber: Yup.string().required(
    i18next.t("field.statementAccountNumberRequired")
  ),
});
