import * as Yup from "yup";
import i18next from "i18next";
import { isAdult } from "util/dateHelpers";

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default Yup.object()
  .shape({
    name: Yup.string().required(i18next.t("field.nameRequired")),
    surname: Yup.string().required(i18next.t("field.surnameRequired")),
    email: Yup.string().email(i18next.t("field.emailFormat")),
    address: Yup.string().required(i18next.t("field.addressRequired")),
    locationId: Yup.string().required(i18next.t("field.locationIdRequired")),
    countryId: Yup.string().required(i18next.t("field.countryIdRequired")),
    //phoneNumber: Yup.string().matches(phoneRegExp, "neispravan format"),
    dateOfBirth: Yup.date()
      .required(i18next.t("field.dateOfBirthRequired"))
      .typeError(i18next.t("field.dateFormatTypeError"))
      .max(new Date(), i18next.t("field.dateFieldMax")),
    passportNumber: Yup.string(),
    genderId: Yup.string().required(i18next.t("field.genderIdRequired")),
    phoneNumber: Yup.string(),
  })
  .test(
    "date-of-birth",
    i18next.t("field.dateOfBirthError"),
    function (values) {
      // if dateOfBirth is not null, check if it is isAdult
      // set error on phoneNumber field if is empty
      if (values.dateOfBirth) {
        if (isAdult(values.dateOfBirth) < 18) {
          return true;
        }
        if (values.phoneNumber) {
          return true;
        }
        return this.createError({
          path: "phoneNumber",
          message: i18next.t("field.phoneNumberAdultReuired"),
        });
      }
    }
  );
