import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modals: {},
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { id, data } = action.payload;
      state.modals[id] = {
        open: true,
        data,
      };
    },
    closeModal: (state, action) => {
      const { id } = action.payload;
      if (state.modals[id]) {
        state.modals[id].open = false;
        state.modals[id].data = {};
      }
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;