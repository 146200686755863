/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import modalConstants from "constants/modalConstants";
import { openModal } from "features/modal/modalSlice";

const WhiteTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const NameList = ({ items }) => {
  const extractNames = (arr) => {
    const names = arr.map((obj) => obj.name);
    const displayedNames =
      names.length > 2
        ? names.slice(0, 2).join(", ") + ", ..."
        : names.join(", ");
    return { displayedNames, names };
  };

  const { displayedNames, names } = extractNames(items);

  return (
    <WhiteTooltip
      title={
        <List style={{ padding: 2, margin: 0 }}>
          {names.map((name, index) => (
            <ListItem style={{ padding: 2 }} key={index}>
              <ListItemText
                primary={name}
                primaryTypographyProps={{
                  style: { color: "rgba(0, 0, 0, 0.87)" },
                }}
              />
            </ListItem>
          ))}
        </List>
      }
    >
      <Typography style={{ fontSize: 14 }}>{displayedNames}</Typography>
    </WhiteTooltip>
  );
};

const useAccommodationUnitTable = ({ page, rowsPerPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "accommodation",
      label: "Naziv objekta",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Naziv smeštajne jedinice",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accommodationUnitType",
      label: "Vrsta smeštajne jedinice",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "unitProperties",
      label: "Karakteristike",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          function extractNames(arr) {
            const names = arr.map((obj) => obj.name);
            if (names.length > 2) {
              return names.slice(0, 2).join(", ") + ", ...";
            }
            return names.join(", ");
          }

          return <NameList items={rowData?.unitProperties} />;
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({ id: modalConstants.EDIT_CODEBOOK_MODAL, data: rowData })
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    const rowData = arrayToObject(tableMeta.rowData, columns);
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_CODEBOOK_MODAL,
                        data: { id: rowData.id },
                      })
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useAccommodationUnitTable;
