export default {
  accommodationId: "",
  accommodationTypeId: "",
  accommodationUnitTypeId: "",
  categoryId: "",
  serviceId: "",
  transportationTypeId: "",
  travelOrganizerId: "",
  number: "",
  date: null,
  offerTypeId: "",
  jid: "",
  locationId: "",
  countryId: "",
  passengers: [
    {
      passengerName: "",
      passengerAddress: "",
      passengerCountryId: "",
      passengerLocationId: "",
      passengerPhoneNumber: "",
      passengerDateOfBirth: "",
      passengerPassportNumber: "",
      departurePlace: "",
    },
  ],
  calculationService: "",
  calculationQuantity: "",
  calculationPriceDin: "",
  calculationPriceEur: "",
  calculationSubTotal: "",
  calculationSaleDin: "",
  calculationSaleEur: "",
  calculationTotal: "",
  departureDate: null,
  appointmentDate: "",
  description: "",
};
