/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import { useAccommodationDropdownQuery } from "features/accommodation/accommodationApiSlice";
import { useAccommodationTypeDropdownQuery } from "features/accommodationType/accommodationTypeApiSlice";
import { useAccommodationUnitTypeDropdownQuery } from "features/accommodationUnitType/accommodationUnitTypeApiSlice";
import { useAgencyPartnerDropdownQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useServiceDropdownQuery } from "features/service/serviceApiSlice";
import { useTransportationTypeDropdownQuery } from "features/transportationType/transportationTypeApiSlice";
import { useUnitPropertyDropdownQuery } from "features/unitProperty/unitPropertyApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const InternalSaleFilter = ({ filterForm }) => {
  const { data: travelOrganizers } = useAgencyPartnerDropdownQuery();
  const { data: offerTypes } = useOfferTypeDropdownQuery();
  const { data: accommodations } = useAccommodationDropdownQuery({});
  const { data: accommodationTypes } = useAccommodationTypeDropdownQuery();
  const { data: accommodationUnitTypes } =
    useAccommodationUnitTypeDropdownQuery();
  const { data: transportationTypes } = useTransportationTypeDropdownQuery();
  const { data: services } = useServiceDropdownQuery();
  const { data: countries } = useCountryDropdownQuery();
  const { data: locations } = useLocationDropdownQuery(
    { id: filterForm.values.CountryId },
    { skip: !filterForm.values.CountryId }
  );
  const { data: unitProperties } = useUnitPropertyDropdownQuery();
  const { t } = useTranslation();

  const offerStatuses = [
    { value: true, text: t("dropdown.activeOffer") },
    { value: false, text: t("dropdown.inactiveOffer") },
  ];
  return (
    <Box component="form">
      <RowContainer>
        <SelectInput
          name="TravelOrganizerId"
          label={t("field.travelOrganizerPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.TravelOrganizerId}
          fullWidth
          style={{ marginRight: "24px" }}
          items={travelOrganizers}
        />
       <SelectInput
          name="OfferNameId"
          label={t("field.offerNamePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.OfferNameId}
          fullWidth
          items={[]}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="CountryId"
          label={t("field.countryPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.CountryId}
          fullWidth
          style={{ marginRight: "24px" }}
          items={countries}
        />
        <SelectInput
          name="OfferTypeId"
          label={t("field.offerTypePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.OfferTypeId}
          fullWidth
          items={offerTypes}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="LocationId"
          label={t("field.locationPlaceholder")}
          onChange={filterForm.handleChange}
          fullWidth
          value={filterForm.values.LocationId}
          disabled={!filterForm.values.CountryId}
          items={locations}
          style={{ marginRight: "24px" }}
        />
        <SelectInput
          name="AccommodationTypeId"
          label={t("field.accommodationTypeIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.AccommodationTypeId}
          fullWidth
          items={accommodationTypes}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="AccommodationId"
          label={t("field.accommodationIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.AccommodationId}
          fullWidth
          items={accommodations}
          style={{ marginRight: "24px" }}
        />
        <SelectInput
          name="UnitPropertyId"
          label={t("field.unitPropertyIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.UnitPropertyId}
          fullWidth
          items={unitProperties}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="AccommodationUnitTypeId"
          label={t("field.accommodationUnitTypePlaceholderFilter")}
          onChange={filterForm.handleChange}
          value={filterForm.values.AccommodationUnitTypeId}
          fullWidth
          items={accommodationUnitTypes}
          style={{ marginRight: "24px" }}
        />
        <SelectInput
          name="ServiceId"
          label={t("field.serviceIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.ServiceId}
          fullWidth
          items={services}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="TransportationTypeId"
          label={t("field.transportationTypePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.TransportationTypeId}
          fullWidth
          style={{ marginRight: "24px" }}
          items={transportationTypes}
        />
        <SelectInput
          name="Active"
          label={t("field.offerStatusPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Active}
          fullWidth
          items={offerStatuses}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
        />
      </RowContainer>
    </Box>
  );
};

export default InternalSaleFilter;
