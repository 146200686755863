import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18nt";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store, persistor } from "./features/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ColorModeProvider from "context/ColorModeContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { srLatn } from "date-fns/locale";
// import { CssBaseline } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={srLatn}
    localeText={{
      fieldDayPlaceholder: () => "dd",
      fieldMonthPlaceholder: () =>  "mm",
      fieldYearPlaceholder: () => "gggg",
    }}
  >
    <HelmetProvider>
      <React.StrictMode>
        <Provider store={store}>
          <ColorModeProvider>
            {/* <CssBaseline /> */}
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </BrowserRouter>
            </PersistGate>
          </ColorModeProvider>
        </Provider>
      </React.StrictMode>
    </HelmetProvider>
  </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
