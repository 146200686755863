import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import { useTranslation } from "react-i18next";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import FormActions from "../FormActions/FormActions";
import { useExchangeRateListQuery } from "features/exchange-rate/exchangeRateSlice";
import { convertDateToISO, getOneDayBeforeDate } from "util/dateHelpers";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import {
  StatementItemRow,
  StatementOrdinalNumber,
} from "./StatementItemsForm.styled";
import ClientSelect from "components/Inputs/ClientSelect";
import PartnerSelect from "components/Inputs/PartnerSelect";
import { DOMESTIC_TYPE, FOREIGN_TYPE } from "constants/statementType";

const StatementItemForm = ({ formik, date, statementId, statementType }) => {
  const { t } = useTranslation();
  const clientPartners = [
    { value: 1, text: "Partner" },
    { value: 2, text: "Klijent" },
  ];
  const statementTypes = [
    { value: 1, text: "Uplata" },
    { value: -1, text: "Isplata" },
  ];

  const isValidInput = (value, decimalPlaces = 2) => {
    const regex = new RegExp(
      `^(?!.*\\..*\\.)[1-9][0-9]*(\\.[0-9]{0,${decimalPlaces}})?$`
    );
    return regex.test(value);
  };

  const { data: exchangeRate, isLoading: isLoadingExchangeRate } =
    useExchangeRateListQuery(
      {
        // one day before the statement date
        page: 0,
        rowsPerPage: 1,
        filter: {
          OnDate: convertDateToISO(getOneDayBeforeDate(date)),
        },
      },
      { skip: !date || statementType === DOMESTIC_TYPE }
    );

  useEffect(() => {
    if (!isLoadingExchangeRate && exchangeRate?.data?.length) {
      if (statementType === FOREIGN_TYPE) {
        formik.values.items.forEach((item, index) => {
          formik.setFieldValue(
            `items[${index}].exchangeRate`,
            exchangeRate?.data[0].rate,
            false
          );
        });
      }
    }
  }, [statementType, date, isLoadingExchangeRate]);

  useEffect(() => {
    if (statementId) {
      formik.values.items.forEach((item, index) => {
        formik.setFieldValue(`items[${index}].statementId`, statementId, false);
      });
    }
  }, [statementId, formik.values.items]);

  useEffect(() => {
    if (statementType === DOMESTIC_TYPE) {
      formik.values.items.forEach((item, index) => {
        formik.setFieldValue(`items[${index}].foreignAmount`, "", true);
        formik.setFieldValue(`items[${index}].exchangeRate`, "", true);
        formik.setFieldValue(`items[${index}].totalAmount`, "", true);
      });
    }
    if (statementType === FOREIGN_TYPE) {
      formik.values.items.forEach((item, index) => {
        formik.setFieldValue(`items[${index}].amount`, "", true);
      });
    }
  }, [statementType]);

  // should calculate total amount like foreignAmount * exchangeRate
  useEffect(() => {
    if (statementType === FOREIGN_TYPE) {
      formik.values.items.forEach((item, index) => {
        if (
          formik.values.items[index].foreignAmount &&
          formik.values.items[index].exchangeRate
        ) {
          const total = parseFloat(
            (item.foreignAmount * item.exchangeRate).toFixed(2)
          );

          formik.setFieldValue(`items[${index}].totalAmount`, total, false);
        }
      });
    }
  }, [formik.values.items, statementType]);

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("statement.sections.statementItemsInfo")}
          </InformationMainText>
        </InformationContainer>
        <>
          {formik?.values.items &&
            formik.values.items.map((item, index) => (
              <React.Fragment key={index}>
                <StatementItemRow>
                  <StatementOrdinalNumber>
                    <Typography variant="h7">{`Redni broj: ${
                      index + 1
                    }`}</Typography>
                  </StatementOrdinalNumber>
                  <Box style={{ width: "100%", marginRight: 24 }}>
                    <RowContainer
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <SelectInput
                        fullWidth
                        name={`items[${index}].creditDebit`}
                        label={t("field.creditDebitPlacejholderRequired")}
                        value={formik.values.items[index].creditDebit || ""}
                        onChange={(e) => {
                          formik.setFieldValue(`items[${index}].invoice`, "");
                          formik.setFieldValue(
                            `items[${index}].creditDebit`,
                            parseInt(e.target.value),
                            true
                          );
                        }}
                        error={
                          formik.touched.items?.[index]?.creditDebit &&
                          Boolean(formik.errors.items?.[index]?.creditDebit)
                        }
                        helperText={
                          formik.touched.items?.[index]?.creditDebit &&
                          formik.errors.items?.[index]?.creditDebit
                        }
                        style={{ marginRight: "24px" }}
                        items={statementTypes}
                      />
                      <SelectInput
                        fullWidth
                        name={`items[${index}].clientPartner`}
                        label={t("field.clientPartnerPlaceholderRequired")}
                        value={formik.values.items[index].clientPartner || ""}
                        onChange={(e) => {
                          formik.setFieldValue(`items[${index}].clientId`, "");
                          formik.setFieldValue(`items[${index}].partnerId`, "");
                          formik.setFieldValue(
                            `items[${index}].clientPartner`,
                            e.target.value,
                            true
                          );
                        }}
                        style={{ marginRight: "24px" }}
                        error={
                          formik.touched.items?.[index]?.clientPartner &&
                          Boolean(formik.errors.items?.[index]?.clientPartner)
                        }
                        helperText={
                          formik.touched.items?.[index]?.clientPartner &&
                          formik.errors.items?.[index]?.clientPartner
                        }
                        items={clientPartners}
                      />
                      {formik.values.items[index].clientPartner === 1 && (
                        <PartnerSelect
                          name={`items[${index}].partnerId`}
                          label={t("field.partnerPlaceholderRequired")}
                          value={formik.values.items[index].partnerId}
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              `items[${index}].partnerId`,
                              newValue ? newValue.value : null,
                              true
                            );
                          }}
                          style={{ marginRight: "24px" }}
                          error={
                            formik.touched.items?.[index]?.partnerId &&
                            Boolean(formik.errors.items?.[index]?.partnerId)
                          }
                          helperText={
                            formik.touched.items?.[index]?.partnerId &&
                            formik.errors.items?.[index]?.partnerId
                          }
                        />
                      )}
                      {formik.values.items[index].clientPartner === 2 && (
                        <ClientSelect
                          name={`items[${index}].clientId`}
                          label={t("field.clientPlaceholderRequired")}
                          value={formik.values.items[index].clientId}
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              `items[${index}].clientId`,
                              newValue ? newValue.value : null,
                              true
                            );
                          }}
                          style={{ marginRight: "24px" }}
                          error={
                            formik.touched.items?.[index]?.clientId &&
                            Boolean(formik.errors.items?.[index]?.clientId)
                          }
                          helperText={
                            formik.touched.items?.[index]?.clientId &&
                            formik.errors.items?.[index]?.clientId
                          }
                        />
                      )}

                      {formik.values.items[index].creditDebit === 1 && (
                        <TextInput
                          fullWidth
                          name={`items[${index}].invoice`}
                          label={t("field.invoiceNumberPlaceholderRequired")}
                          value={formik.values.items[index].invoice || ""}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `items[${index}].invoice`,
                              e.target.value
                            )
                          }
                          style={{ marginRight: "24px" }}
                          error={
                            formik.touched.items?.[index]?.invoice &&
                            Boolean(formik.errors.items?.[index]?.invoice)
                          }
                          helperText={
                            formik.touched.items?.[index]?.invoice &&
                            formik.errors.items?.[index]?.invoice
                          }
                        />
                      )}

                      <TextInput
                        fullWidth
                        name={`items[${index}].reservationNumber`}
                        label={t("field.reservationNumberPlaceholder")}
                        value={
                          formik.values.items[index].reservationNumber || ""
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `items[${index}].reservationNumber`,
                            e.target.value
                          )
                        }
                      />
                    </RowContainer>
                    <RowContainer>
                      <TextInput
                        fullWidth
                        name={`items[${index}].description`}
                        label={t("field.statementDescriptionPlaceholder")}
                        value={formik.values.items[index].description || ""}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `items[${index}].description`,
                            e.target.value
                          )
                        }
                        style={{ marginRight: "24px" }}
                      />
                      {statementType === DOMESTIC_TYPE && (
                        <TextInput
                          fullWidth
                          name={`items[${index}].amount`}
                          type="number"
                          label={t("field.amountPlaceholderRequired")}
                          value={formik.values.items[index].amount || ""}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `items[${index}].amount`,
                              parseFloat(e.target.value)
                            )
                          }
                          error={
                            formik.touched.items?.[index]?.amount &&
                            Boolean(formik.errors.items?.[index]?.amount)
                          }
                          helperText={
                            formik.touched.items?.[index]?.amount &&
                            formik.errors.items?.[index]?.amount
                          }
                        />
                      )}
                      {statementType === FOREIGN_TYPE && (
                        <>
                          <TextInput
                            fullWidth
                            name={`items[${index}].foreignAmount`}
                            type="number"
                            label={t("field.foreignAmountPlaceholderRequired")}
                            value={
                              formik.values.items[index].foreignAmount || ""
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === "" ||
                                isValidInput(inputValue)
                              ) {
                                formik.setFieldValue(
                                  `items[${index}].foreignAmount`,
                                  parseFloat(inputValue)
                                );
                              }
                            }}
                            style={{ marginRight: "24px" }}
                            error={
                              formik.touched.items?.[index]?.foreignAmount &&
                              Boolean(
                                formik.errors.items?.[index]?.foreignAmount
                              )
                            }
                            helperText={
                              formik.touched.items?.[index]?.foreignAmount &&
                              formik.errors.items?.[index]?.foreignAmount
                            }
                          />

                          <TextInput
                            fullWidth
                            name={`items[${index}].exchangeRate`}
                            type="number"
                            label={t("field.ratePlaceholderRequired")}
                            value={
                              formik.values.items[index].exchangeRate || ""
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === "" ||
                                isValidInput(inputValue, 4)
                              ) {
                                formik.setFieldValue(
                                  `items[${index}].exchangeRate`,
                                  parseFloat(inputValue)
                                );
                              }
                            }}
                            error={
                              formik.touched.items?.[index]?.exchangeRate &&
                              Boolean(
                                formik.errors.items?.[index]?.exchangeRate
                              )
                            }
                            helperText={
                              formik.touched.items?.[index]?.exchangeRate &&
                              formik.errors.items?.[index]?.exchangeRate
                            }
                            style={{ marginRight: "24px" }}
                          />
                          <TextInput
                            fullWidth
                            disabled
                            name={`items[${index}].totalAmount`}
                            type="number"
                            label={t("field.totalAmountPlaceholderRequired")}
                            value={formik.values.items[index].totalAmount || ""}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `items[${index}].totalAmount`,
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        </>
                      )}
                    </RowContainer>
                  </Box>
                  <IconButton
                    color="error"
                    onClick={() => {
                      if (formik.values.items.length > 1) {
                        formik.setFieldValue(
                          "items",
                          formik.values.items.filter((_, i) => i !== index) // Remove the item
                        );
                      } else {
                        formik.setFieldValue("items", [
                          {
                            creditDebit: "",
                            amount: "",
                            foreignAmount: "",
                            description: "",
                            exchangeRate: exchangeRate?.data[0].rate || "",
                            clientPartner: "",
                            clientId: null,
                            partnerId: null,
                            invoice: "",
                            reservationNumber: "",
                            totalAmount: "",
                          },
                        ]);
                      }
                    }}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </StatementItemRow>
                {index !== formik.values.items.length - 1 && (
                  <Divider style={{ marginBottom: 24 }} />
                )}
              </React.Fragment>
            ))}
          <Box style={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() =>
                formik.setFieldValue("items", [
                  ...formik.values.items,
                  {
                    creditDebit: "",
                    amount: "",
                    foreignAmount: "",
                    description: "",
                    exchangeRate: exchangeRate?.data[0].rate || "",
                    clientPartner: "",
                    clientId: null,
                    partnerId: null,
                    invoice: "",
                    reservationNumber: "",
                    statementId: statementId,
                    totalAmount: "",
                  },
                ])
              }
            >
              {t("statement.addNewItem")}
            </Button>
          </Box>
        </>
      </FormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleConfirm={formik.handleSubmit}
        handleCancel={() => {
          formik.setFieldValue("items", [
            {
              creditDebit: "",
              amount: "",
              foreignAmount: "",
              description: "",
              exchangeRate: exchangeRate?.data[0].rate || "",
              clientPartner: "",
              clientId: null,
              partnerId: null,
              invoice: "",
              reservationNumber: "",
              totalAmount: "",
            },
          ]);
        }}
      />
    </Box>
  );
};

StatementItemForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  date: PropTypes.string,
  statementId: PropTypes.number,
  statementType: PropTypes.number,
};

export default StatementItemForm;
