/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const BankAccountFilter = ({ filterForm }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="BankName"
          label={t("field.bankNameFilter")}
          style={{ marginRight: 24 }}
          onChange={filterForm.handleChange}
          value={filterForm.values.BankName}
        />
        <TextInput
          fullWidth
          name="AccountNumber"
          label={t("field.accountNumberFilter")}
          onChange={filterForm.handleChange}
          value={filterForm.values.AccountNumber}
        />
      </RowContainer>
    </Box>
  );
};

export default BankAccountFilter;
