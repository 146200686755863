import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CardContainer } from "./PageCard.styled";

const PageCard = ({ children, path, Icon }) => {
  const navigate = useNavigate();
  return (
    <CardContainer onClick={() => navigate(path)}>
      <Icon fontSize="large" />
      <Typography
        variant="h5"
        sx={{
          marginLeft: "20px",
          marginTop: "5px",
        }}
      >
        {children}
      </Typography>
    </CardContainer>
  );
};

PageCard.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  Icon: PropTypes.object,
};

export default PageCard;
