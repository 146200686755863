import { PAGES } from "constants/pages";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";
import Title from "components/Title/Title";
import PartnerForm from "components/Forms/PartnerForm/PartnerForm";
import {
  useAgencyPartnerDetailsQuery,
  useCreateExternalPartnerMutation,
  useEditExternalPartnerMutation,
} from "features/agencyPartner/agencyPartnerApiSlice";
import partnerInitialValues from "initialValues/partnerInitialValues";
import agencyPartnerExternalValidation from "validations/agencyPartnerExternalValidation";

const PartnerAddEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDPARTNER.route;
  const navigate = useNavigate();

  const {
    data: partnerDetails,
    isLoading: isLoadingPartnerDetails,
    error,
  } = useAgencyPartnerDetailsQuery({ id: id }, { skip: isAdd });
  const [createExternalPartner, { isLoading: isLoadingCreateExternalPartner }] =
    useCreateExternalPartnerMutation();
  const [editPartner, { isLoading: isLoadingEditPartner }] =
    useEditExternalPartnerMutation();

  const handleSubmit = async (values) => {
    if (!id) {
      await createExternalPartner(values)
        .unwrap()
        .then(() => {
          if (!isLoadingCreateExternalPartner) {
            navigate(PAGES.PARTNERS.route);
            makeToastMessage(t("partners.addSuccessMessage"));
          }
        })
        .catch((e) => makeErrorToastMessage(e.data.message));
    }

    if (id) {
      await editPartner(values)
        .unwrap()
        .then(() => {
          if (!isLoadingEditPartner) {
            navigate(PAGES.PARTNERS.route);
            makeToastMessage(t("partners.editSuccessMessage"));
          }
        })
        .catch((e) => makeErrorToastMessage(e.data.message));
    }
  };

  const formik = useFormik({
    initialValues:
      id && !isLoadingPartnerDetails ? partnerDetails : partnerInitialValues,
    validationSchema: agencyPartnerExternalValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingPartnerDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingPartnerDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }
  return (
    <PageContainer>
      <Title title={id ? t("partners.editTitle") : t("partners.addTitle")} />
      <PartnerForm formik={formik} />
    </PageContainer>
  );
};

export default PartnerAddEditPage;
