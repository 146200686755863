/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { HeaderProfileContainer } from "./HeaderProfile.styled";
import PopoverComponent from "components/Popover/Popover";
import HeaderProfilePopoverContent from "./HeaderProfilePopoverContent/HeaderProfilePopoverContent";
import { useUserBasicProfileQuery } from "features/user/userApiSlice";
import { Avatar } from "@mui/material";
const HeaderProfile = () => {
  const { isLoading } = useUserBasicProfileQuery();
  const { data: userProfile } = useUserBasicProfileQuery();
  return (
    <HeaderProfileContainer>
      {!isLoading && (
        <PopoverComponent
          contentContainerStyles={{
            "& .MuiPopover-paper": {
              borderRadius: "8px",
              overflow: "hidden",
              backgroundColor: "transparent",
            },
          }}
          trigger={
            <Avatar alt="UserProfilePicture" src={userProfile?.userPhoto} />
          }
          content={<HeaderProfilePopoverContent />}
        />
      )}
    </HeaderProfileContainer>
  );
};

HeaderProfile.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfile;
