/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import Status from "components/Status/Status";

const useLicenceTable = ({ page, rowsPerPage }) => {
  const { isSuperAdmin } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "agencyId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "number",
      label: "Broj licence/ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Datum aktivacije licence",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return format(new Date(rowData.startDate), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "endDate",
      label: "Datum isteka licence",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return format(new Date(rowData.endDate), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "licenceStatus",
      label: "Status licence",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Status
              active={rowData.licenceStatus === "Aktivna"}
              label={rowData.licenceStatus}
            />
          );
        },
      },
    },
    {
      name: "updatedOn",
      label: "Poslednje izmene",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return format(new Date(rowData.updatedOn), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        display: isSuperAdmin,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex" }}>
              <Tooltip
                title={t("tableCols.agency.tooltip.details")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    navigate(
                      PAGES.EDITLICENCE.route.replace(":id", rowData.id),
                      { state: { editAgencyId: 2 } }
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useLicenceTable;
