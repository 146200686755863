import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router";
import useAuth from "hooks/useAuth";
import { PAGES } from "constants/pages";
import MainLayout from "components/Layout/MainLayout";

const AuthRoute = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isLoggedIn, role, hasRole, agencyType } = useAuth();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // Check for roles authorization
    let rolesAuthorized = false;
    if (props?.roles?.length === 0) {
      rolesAuthorized = true;
    } else {
      rolesAuthorized = props?.roles?.reduce((prevValue, currentValue) => {
        if (prevValue === true) return prevValue;
        if (hasRole(currentValue)) return true;
        return false;
      }, false);
    }

    // Check for agencyType authorization
    let agencyTypeAuthorized = false;
    if (props?.agencyType?.length === 0) {
      agencyTypeAuthorized = true;
    } else {
      agencyTypeAuthorized = props?.agencyType?.includes(agencyType);
    }

    // Update authorized state based on roles and agencyType
    setAuthorized(rolesAuthorized && agencyTypeAuthorized);
    setIsLoaded(true);
  }, [isLoggedIn, role, props?.roles, props?.agencyType, agencyType]);

  return isLoaded ? (
    isLoggedIn ? (
      authorized ? (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ) : (
          <Navigate to={PAGES.BASE.route} />
      )
    ) : (
      <Navigate to={PAGES.LOGIN.route} />
    )
  ) : (
    <></>
  );
};

AuthRoute.propTypes = {
  roles: PropTypes.array,
  agencyType: PropTypes.array,
};

AuthRoute.defaultProps = {
  agencyType: [], // Set default value for agencyType
};

export default AuthRoute;
