import React from "react";
import PropTypes from "prop-types";
import { HeaderProfileBarContainer } from "./HeaderProfileBar.styled";
import HeaderProfile from "./HeaderProfile/HeaderProfile";

const HeaderProfileBar = () => {
  
  return (
    <HeaderProfileBarContainer>
        <>
          <HeaderProfile />
        </>
    </HeaderProfileBarContainer>
  );
};

HeaderProfileBar.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfileBar;