import React from "react";
import PropTypes from "prop-types";
import { useAgencyPartnerDetailsQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import { PartnerCardContainer, PartnerCardInfo } from "./PartnerCard.styled";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocationDetailsQuery } from "features/location/locationApiSlice";

const PartnerCard = ({ partnerId }) => {
  const { t } = useTranslation();
  const { data: partner, error } = useAgencyPartnerDetailsQuery({
    id: partnerId,
  });

  const { data: location } = useLocationDetailsQuery(
    { id: partner?.locationId },
    { skip: !partner }
  );

  return (
    <PartnerCardContainer>
      {error ? (
        <Typography variant="body1">{t("common.errorOccurred")}</Typography>
      ) : (
        <>
          <PartnerCardInfo>
            <Typography variant="h6">{t("common.partnerName")}</Typography>
            <Typography variant="body1">{partner?.name}</Typography>
          </PartnerCardInfo>

          <PartnerCardInfo>
            <Typography variant="h6">{t("common.address")}</Typography>
            <Typography variant="body1">{partner?.address}</Typography>
          </PartnerCardInfo>

          <PartnerCardInfo>
            <Typography variant="h6">{t("common.location")}</Typography>
            <Typography variant="body1">{location?.name}</Typography>
          </PartnerCardInfo>
          <PartnerCardInfo>
            <Typography variant="h6">{t("common.email")}</Typography>
            <Typography variant="body1">{partner?.email}</Typography>
          </PartnerCardInfo>
          <PartnerCardInfo>
            <Typography variant="h6">{t("common.pib")}</Typography>
            <Typography variant="body1">{partner?.pib}</Typography>
          </PartnerCardInfo>
        </>
      )}
    </PartnerCardContainer>
  );
};
PartnerCard.propTypes = {
  partnerId: PropTypes.number.isRequired,
};

export default PartnerCard;
